import React from 'react'
import { useHistory } from 'react-router-dom'

// STYLE
import './LandingPage.scss'

// COMPONENTS
import NewsCard from 'components/NewsCard/NewsCard'

// ASSETS

// MATERIAL-UI
import { Grid } from '@material-ui/core'
import LastestNewsCard from 'components/LastestNewsCard/LastestNewsCard'

const LandingPage = () => {
  const history = useHistory()

  return (
    <div className="page-container">
      <div className="landing-page-container">
        <Grid container>
          <Grid item xs={7}>
            <div className="section-title">Top reads</div>
            <NewsCard />
            <NewsCard />
            <NewsCard />
          </Grid>
          <Grid item xs={5}>
            <div className="section-title">Lastest news</div>
            <LastestNewsCard />
            <LastestNewsCard />
            <LastestNewsCard />
            <LastestNewsCard />
            <LastestNewsCard />
          </Grid>
        </Grid>
        <div className="view-button-container">
          <div className="view-button" onClick={() => history.push(`/news/all-news`)}>
            View All News
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
