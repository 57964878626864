export const fiscalYear = [
  { indicator: 'Fiscal Year Ends', value: 'Nov 28, 2019' },
  { indicator: 'Most Regent Quarter (mrq) Year Ends', value: 'Aug 19, 2020' },
]

export const profitability = [
  { indicator: 'Net Revenue Margin', value: '31.05%' },
  // { indicator: 'Operating Margin (ttm)', value: '32.10%' },
]

export const managementEfectiveness = [
  { indicator: 'Return on Assets (ttm)', value: '11.75%' },
  { indicator: 'Return on Equity (ttm)', value: '35.17%' },
]

export const incomeStatement = [
  { indicator: 'Gross Revenue', value: '12.44M' },
  { indicator: 'Net Revenue', value: '11.24M' },
  { indicator: 'Revenue Per REbS (ttm)', value: '25.81' },
  { indicator: 'Quarterly Revenue Growth (yoy)', value: '13.80%' },
  { indicator: 'Gross Profit (ttm)', value: '9.5M' },
  // { indicator: 'EBITDA', value: '4.64M' },
  { indicator: 'Net Income Avi to Common (ttm)', value: '3.86M' },
  { indicator: 'Diluted Eps (ttm)', value: '7.94' },
  { indicator: 'Quarterly Earnings Growth (yoy)', value: '20.40%' },
]

export const incomeStatement2 = [
  { indicator: 'Total cash (mrq)', value: '5.26M' },
  { indicator: 'Total Cash Per Share (mrq)', value: '10.97' },
  // { indicator: 'Total Debt (mrq)', value: '4.73M' },
  // { indicator: 'Total Debt/Equity (mrq)', value: '40.35' },
  { indicator: 'Current Ratio (mrq)', value: '1.44' },
  { indicator: 'Book Value Per Share (mrq)', value: '24.44' },
]

export const cashFlowStatement = [
  { indicator: 'Operating Cash Flow (ttm)', value: '5.32M' },
  { indicator: 'Levered Free Cash Flow (ttm)', value: '3.99M' },
]

export const stockPriceHistory = [
  { indicator: 'Beta (5Y Monthly)', value: '0.97' },
  { indicator: '52-Week Change 3', value: '71.89%' },
  { indicator: 'Nafram index 52 week change', value: '11.29%' },
  { indicator: '52 Week High 3', value: '536.88' },
  { indicator: '52 Week Low 3', value: '255.13' },
  { indicator: '50-Day Moving Average 3', value: '485.12' },
  { indicator: '200-Day Moving Average 3', value: '441.12' },
]

export const shareStatistics = [
  { indicator: 'Avg Vol (3 month) 3', value: '2.86M' },
  { indicator: 'Avg Vol (10 day) 3', value: '1.11M' },
  { indicator: 'Shares Outstanding 5', value: '479.72M' },
  { indicator: 'Float', value: '477.12M' },
  { indicator: '% Held by Insiders 1', value: '0.29%' },
  { indicator: '% Held by Institutions 1', value: '89.10%' },
  { indicator: 'Shares Short (Oct 14,2020) 4', value: '4.25M' },
  { indicator: 'Short Ratio (Oct 14,2020) 4', value: '1.33' },
  { indicator: 'Short % of Float (Oct 14,2020) 4', value: '0.89%' },
  { indicator: 'Short % of Shares Outstanding (Oct 14,2020) 4', value: '0.87%' },
  { indicator: 'Shares Short (prior month Sep 14,2020) 4', value: '3.97M' },
]

export const dividendsAndSplits = [
  { indicator: 'Forward Annual Dividend Rate 4', value: 'N/A' },
  { indicator: 'Forward Annual Dividend Yield 4', value: 'N/A' },
  { indicator: 'Trailing Annual Dividend Rate 3', value: 'N/A' },
  { indicator: 'Trailing Annual Dividend Yield 3', value: 'N/A' },
  { indicator: '5 Year Average Dividend Yield 4', value: 'N/A' },
  { indicator: 'Payout Ratio 4', value: '0.00%' },
  { indicator: 'Dividend Date 3', value: 'N/A' },
  { indicator: 'Ex-Dividend Date 4', value: 'Mar 23, 2005' },
  { indicator: 'Last Split Factor 2', value: '2:1' },
  { indicator: 'Last Split Date 3', value: 'May 23,2005' },
]
