import { Grid } from '@material-ui/core'
import React from 'react'
import moment from 'moment'

// STYLE
import './MainNews.scss'

// COMPONENTS

// ASSETS
import Photo from 'assets/images/MainNews.svg'

// MOCKS
let title = 'Earnings for apartment owners show the pain of urban flight '
let content =
  'Decidedly weak quarterly earnings reports from major apartment real estate investment trusts this week paint a bleak picture for some of the largest urban rental markets.'
let agency = 'Real Estate'
let newsDate = '2021-08-12T13:00:00Z'

const MainNews = () => {
  return (
    <Grid container>
      <Grid item xs={12} className="news-main-news-continer">
        <div className="news-main-news-photo-container">
          <img src={Photo} alt="" className="news-main-news-photo" />
        </div>
        <div className="news-main-news-content-container">
          <div className="news-main-news-title">{title}</div>
          <div className="news-main-news-content">
            {content.length > 190 ? `${content.substring(0, 190)}...` : content}
          </div>
          <div className="news-main-news-agency">{agency}</div>
          <div className="news-main-news-date">{moment(newsDate).fromNow()}</div>
          <div className="news-main-news-view-more">View More ></div>
        </div>
      </Grid>
    </Grid>
  )
}

export default MainNews
