import React, { useState } from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import classNames from 'classnames'

// STYLE
import '../ChartsAndHistoricalData.scss'

// MATERIAL-UI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
} from '@material-ui/core'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

// MOCKS
import { data } from 'mocks/ChartAndHistoricalDataMocks'
const labels = [['Date', 'Open', 'High', 'Low', 'Close', 'Volume']]

const ChartsAndHistoricalDataTable = () => {
  const [dateFrom, setDateFrom] = useState(new Date())
  const [dateTo, setDateTo] = useState(new Date())
  const [show, setShow] = useState(10)
  const [frequency, setFrequency] = useState(10)

  const handleSelectDateFrom = date => {
    setDateFrom(date)
  }

  const handleSelectDateTo = date => {
    setDateTo(date)
  }

  const handleChangeShow = show => {
    setShow(show)
  }

  const handleChangeFrequency = frequency => {
    setFrequency(frequency)
  }

  return (
    <Grid container className="charts-and-historical-data-table-container">
      <TableContainer component={Paper}>
        <Grid container className="table-buttons">
          <Grid item xs={4} className={classNames('row', 'pickers-container')}>
            <div>From:</div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="date-from"
                format="MM/dd/yyyy"
                value={dateFrom}
                onChange={handleSelectDateFrom}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            <div>To:</div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="date-to"
                format="MM/dd/yyyy"
                value={dateTo}
                onChange={handleSelectDateTo}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={4} className={classNames('row', 'select-container')}>
            <div>Show:</div>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={show}
              onChange={handleChangeShow}
            >
              <MenuItem value={10}>Historical Prices</MenuItem>
              <MenuItem value={20}>Historical Prices</MenuItem>
              <MenuItem value={30}>Historical Prices</MenuItem>
            </Select>
            <div>Frequency:</div>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={frequency}
              onChange={handleChangeFrequency}
            >
              <MenuItem value={10}>Daily</MenuItem>
              <MenuItem value={20}>Monthly</MenuItem>
              <MenuItem value={30}>Yearly</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={4} className="button-container">
            <div className="button">Apply</div>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              {labels[0].map(item => (
                <TableCell align="center" key={`${item}`}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRow key={`${item}_${index}`}>
                <TableCell>{item.date}</TableCell>
                <TableCell align="center">{item.open}</TableCell>
                <TableCell align="center">{item.high}</TableCell>
                <TableCell align="center">{item.low}</TableCell>
                <TableCell align="center">{item.close}</TableCell>
                <TableCell>{item.volume}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

ChartsAndHistoricalDataTable.propTypes = {
  // data: PropTypes.array,
}

export default ChartsAndHistoricalDataTable
