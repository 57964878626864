import React from 'react'

// STYLE
import './CompanyProfile.scss'

// COMPONENTS
import CompanyPreview from 'components/CompanyPreview/CompanyPreview'
import MenuTabs from 'components/MenuTabs/MenuTabs'
import NewsSection from 'components/NewsSection/NewsSection'
import Statistics from 'pages/CompanyProfile/components/Statistics/Statistics'
import PropertyProfile from 'pages/CompanyProfile/components/PropertyProfile/PropertyProfile'
import Analysis from 'pages/CompanyProfile/components/Analysis/Analysis'
import Summary from 'pages/CompanyProfile/components/Summary/Summary'
import ChartsAndHistoricalData from 'pages/CompanyProfile/components/ChartsAndHistoricalData/ChartsAndHistoricalData'

let tabs = [
  { value: 0, label: 'Summary', component: <Summary key={'Summary'} /> },
  {
    value: 1,
    label: 'Charts & Historical data',
    component: <ChartsAndHistoricalData key={'ChartsAndHistoricalData'} />,
  },
  { value: 2, label: 'Statistics', component: <Statistics key={'Statistics'} /> },
  { value: 3, label: 'Property Profile', component: <PropertyProfile key={'PropertyProfile'} /> },
  { value: 4, label: 'Analysis', component: <Analysis key={'Analysis'} /> },
]

const CompanyProfile = () => {
  return (
    <div className="page-container">
      <CompanyPreview />
      <MenuTabs tabs={tabs} />
      <NewsSection />
    </div>
  )
}

export default CompanyProfile
