import { Grid } from '@material-ui/core'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

// STYLE
import './BriefCard.scss'

// ASSETS

// MOCKS
import Photo from 'assets/images/MorningBriefPhoto.svg'

const title =
  'Stocks making the biggest moves in the premarket: Clorox, Estee Lauder,Dunkin’ Brands & more'
const description =
  'Take a look at some of the biggest movers in the premarket:Clorox (CLX) – The household products maker reported quarterly earnings of $3.22 per share, compared to a consen.'
const sector = 'Stocks'
const date = '2021-08-12T13:00:00Z'

// COMPONENTS

const BriefCard = ({ type = '' }) => {
  const history = useHistory()

  const handleOnClick = () => {
    history.push(`/insights-entry/id`)
  }

  return (
    <Grid item className="morning-brief-article-container">
      <div className="morning-brief-article-photo-container">
        <img src={Photo} alt="" className="morning-brief-article-photo" />
      </div>
      <div className="morning-brief-article-content-container">
        <div className="morning-brief-article-title">{title}</div>
        <div className="morning-brief-article-content">
          {description.length > 100 ? `${description.substring(0, 100)}...` : description}
        </div>
        <div className="morning-brief-article-sector">{sector}</div>
        <div className="morning-brief-article-date">{moment(date).format('MMM DD YYYY')}</div>
        <div className="morning-brief-article-view-more" onClick={handleOnClick}>
          View More >
        </div>
      </div>
    </Grid>
  )
}

BriefCard.propTypes = {
  type: PropTypes.string,
}

export default BriefCard
