import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import '../Analysis.scss'

// MATERIAL-UI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'

const AnalysisTable = ({ data = [], title = null, type = null }) => {
  function createData(indicator, currentQtr, nextQrt, currentYear, nextYear) {
    return { indicator, currentQtr, nextQrt, currentYear, nextYear }
  }

  const rows = data.map(item => {
    return createData(
      item.indicator,
      item.currentQtr,
      item.nextQrt,
      item.currentYear,
      item.nextYear,
    )
  })

  function createDataGrowth(indicator, crs, industry, sectors, sp500) {
    return { indicator, crs, industry, sectors, sp500 }
  }

  const rowsGrowth = data.map(item => {
    return createDataGrowth(item.indicator, item.crs, item.industry, item.sectors, item.sp500)
  })

  return (
    <Grid container className="analysis-table-container">
      {type === null ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="table-title">{title}</TableCell>
                <TableCell align="left">Current Qrt (Nov 2020)</TableCell>
                <TableCell align="left">Next Qrt (Nov 2021)</TableCell>
                <TableCell align="left">Current Year (2020)</TableCell>
                <TableCell align="left">Next Year (2021)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.indicator}>
                  <TableCell component="th" scope="row" className="row-title">
                    {row.indicator}
                  </TableCell>
                  <TableCell align="left">{row.currentQtr}</TableCell>
                  <TableCell align="left">{row.nextQrt}</TableCell>
                  <TableCell align="left">{row.currentYear}</TableCell>
                  <TableCell align="left">{row.nextYear}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="table-title">{title}</TableCell>
                <TableCell align="left">CRS</TableCell>
                <TableCell align="left">Industry</TableCell>
                <TableCell align="left">Sector(s)</TableCell>
                <TableCell align="left">S&P 500</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsGrowth.map(row => (
                <TableRow key={row.indicator}>
                  <TableCell component="th" scope="row" className="row-title">
                    {row.indicator}
                  </TableCell>
                  <TableCell align="left">{row.crs}</TableCell>
                  <TableCell align="left">{row.industry}</TableCell>
                  <TableCell align="left">{row.sectors}</TableCell>
                  <TableCell align="left">{row.sp500}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  )
}

AnalysisTable.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string,
}

export default AnalysisTable
