import React from 'react'

// STYLE
import './Layout.scss'

// COMPONENTS
import Header from 'components/Header/Header'
import SubMenu from 'components/SubMenu/SubMenu'
import Footer from 'components/Footer/Footer'
import Preferences from 'components/Preferences/Preferences'

const Layout = ({ children }) => {
  return (
    <div className="layout-container">
      <div className="layout-header">
        <Header />
        <SubMenu />
      </div>
      <div className="layout-content">{children}</div>
      <div className="layout-footer">
        <Footer />
      </div>
      <Preferences />
    </div>
  )
}

export default Layout
