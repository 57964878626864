import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './Statistics.scss'

// MATERIAL-UI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'

const IndicatorValueTable = ({ title = null, data = [] }) => {
  function createData(indicator, value) {
    return { indicator, value }
  }

  const row = data.map(item => {
    return createData(item.indicator, item.value)
  })

  return (
    <Grid container>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="table-title">{title}</TableCell>
              <TableCell> </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row.map(row => (
              <TableRow key={row.indicator}>
                <TableCell component="th" scope="row" className="row-title">
                  {row.indicator}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

IndicatorValueTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
}

export default IndicatorValueTable
