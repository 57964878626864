import React from 'react'
import { useHistory } from 'react-router-dom'

// STYLE
import './Stocks.scss'

// COMPONENTS
import MarketTable from 'components/MarketTable/MarketTable'
import NewsSection from 'components/NewsSection/NewsSection'

// MOCKS
import { markets, gainers, decliners } from 'mocks/MarketMocks'

const PreviewStocks = () => {
  const history = useHistory()

  const handleNavigateToAllStockMarket = () => {
    history.push('stocks/all-markets')
  }

  return (
    <div className="page-container">
      <div className="stocks-page-title-container">
        <div className="stocks-page-title">Industrial Stock Market Preview</div>
        <div className="view-all" onClick={handleNavigateToAllStockMarket}>
          View All Stock market Activity
        </div>
      </div>
      <MarketTable title="Gainers" secondaryTitle="Top Gainers" data={gainers} viewButton={true} />
      <MarketTable
        title="Decliners"
        secondaryTitle="Top Decliners"
        data={decliners}
        viewButton={true}
      />
      <MarketTable title="Most active by share volume" data={markets} />
      <MarketTable title="Most active by dollar volume" data={markets} />
      <NewsSection />
    </div>
  )
}

export default PreviewStocks
