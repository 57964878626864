import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './Statistics.scss'

// MATERIAL-UI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'

const ValuationMeasuresTable = () => {
  function createData(
    name,
    firstValue,
    secondValue,
    thirdValue,
    forthValue,
    fifthValue,
    sixtValue,
  ) {
    return { name, firstValue, secondValue, thirdValue, forthValue, fifthValue, sixtValue }
  }

  const rows = [
    createData('Market Cap (intraday)', 231.992, 246.433, 185.572, 166.692, 143.123, 112.512),
    createData('REbS Value', 231.992, 246.433, 185.572, 166.692, 143.123, 112.512),
    createData('Trailing P/E', 231.992, 246.433, 185.572, 166.692, 143.123, 112.512),
    createData('Forward P/E', 231.992, 246.433, 185.572, 166.692, 143.123, 112.512),
    createData('PEG Ratio (5yr expected)', 231.992, 246.433, 185.572, 166.692, 143.123, 112.512),
    createData('Price/Sales (ttm)', 231.992, 246.433, 185.572, 166.692, 143.123, 112.512),
    createData('Price/Book (mrq)', 231.992, 246.433, 185.572, 166.692, 143.123, 112.512),
    createData('REbS Value/Gross Revenue', 231.992, 246.433, 185.572, 166.692, 143.123, 112.512),
    createData('REbS Value/Net Revenue', 231.992, 246.433, 185.572, 166.692, 143.123, 112.512),
  ]

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className="section-title">Valuation Measures</div>
      </Grid>
      <TableContainer component={Paper} className="valuation-measures-table-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Current</TableCell>
              <TableCell align="right">8/31/2020</TableCell>
              <TableCell align="right">5/31/2020</TableCell>
              <TableCell align="right">2/29/2020</TableCell>
              <TableCell align="right">11/30/2019</TableCell>
              <TableCell align="right">8/31/2019</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" className="row-title">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.firstValue}</TableCell>
                <TableCell align="right">{row.secondValue}</TableCell>
                <TableCell align="right">{row.thirdValue}</TableCell>
                <TableCell align="right">{row.forthValue}</TableCell>
                <TableCell align="right">{row.fifthValue}</TableCell>
                <TableCell align="right">{row.sixtValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

ValuationMeasuresTable.propTypes = {}

export default ValuationMeasuresTable
