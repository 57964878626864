import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import classNames from 'classnames'

// MATERIAL-UI
import { Grid, Card, Select, MenuItem } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

// ASSETS
import './BigChartComponent.scss'

// COMPONENTS
import Chart from 'react-apexcharts'

const BigChartComponent = props => {
  const [dateFrom, setDateFrom] = useState(new Date())
  const [dateTo, setDateTo] = useState(new Date())
  const [frequency, setFrequency] = useState(10)
  const [selectedPeriod, setSelectedPeriod] = useState('1D')
  // eslint-disable-next-line
  const [xAxisData, setXAxisData] = useState([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
  ])
  // eslint-disable-next-line
  const [yAxisData, setYAxisData] = useState([
    '411',
    '234',
    '161',
    '222',
    '471',
    '511',
    '511',
    '910',
    '581',
    '711',
    '411',
    '351',
    '612',
    '511',
    '771',
    '332',
    '221',
    '111',
    '711',
    '512',
    '555',
    '312',
    '111',
    '24',
    '500',
    '900',
    '1200',
    '700',
    '300',
    '500',
  ])

  let chartsSmall1COptions = {
    chart: {
      toolbar: {},
      sparkline: {},
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      color: '#33BD6D',
      curve: 'smooth',
      width: 2,
    },
    colors: ['#41D6A3'],
    fill: {
      color: '#33BD6D',
      opacity: 0.4,
    },
    xaxis: {
      categories: xAxisData,
    },
    yaxis: {
      min: 0,
      max: 1300,
    },
  }

  const chartSeries = [
    {
      data: yAxisData,
    },
  ]
  const chartLabels = [
    {
      data: xAxisData,
    },
  ]

  //   const getData = async () => {
  //     setIsLoading(true)
  //     var check = moment(new Date(), 'YYYY/MM/DD')
  //     let monthForRequest = ''

  //     if (selectedMonth) {
  //       monthForRequest = selectedMonth
  //     } else {
  //       monthForRequest = check.format('M')
  //     }

  //     await REST.get(
  //       `/api/v1/statistics/employer/me/jobs/~/timesheets/~/hours/chart/${check.format(
  //         'YYYY',
  //       )}/${monthForRequest}`,
  //     )
  //       .then(answer => {
  //         var x = []
  //         var y = []
  //         var max = 0

  //         // eslint-disable-next-line
  //         answer.data.map(item => {
  //           x.push(item.x)
  //           y.push(item.y)
  //           if (item.y > max) {
  //             max = item.y
  //           }
  //         })

  //         setYAxisMax(max + 1)
  //         setXAxisData(x)
  //         setYAxisData(y)
  //       })
  //       .catch(err => {})
  //     setIsLoading(false)
  //   }

  //   useEffect(() => {
  //     getData()
  //     // eslint-disable-next-line
  //   }, [])

  //   useEffect(() => {
  //     getData()
  //     // eslint-disable-next-line
  //   }, [selectedMonth])

  let chartHeight = 300

  const handleChangeSelectedPeriod = (event, newAlignment) => {
    console.log(newAlignment)
    setSelectedPeriod(newAlignment)
  }

  const handleSelectDateFrom = date => {
    setDateFrom(date)
  }

  const handleSelectDateTo = date => {
    setDateTo(date)
  }

  const handleChangeFrequency = frequency => {
    setFrequency(frequency)
  }

  return (
    <Grid container className="small-chart-component-container">
      <div className="small-chart-component-header">
        <ToggleButtonGroup
          value={selectedPeriod}
          exclusive
          onChange={handleChangeSelectedPeriod}
          aria-label="text alignment"
        >
          <ToggleButton value="1D">1D</ToggleButton>
          <ToggleButton value="5D">5D</ToggleButton>
          <ToggleButton value="1M">1M</ToggleButton>
          <ToggleButton value="6M">6M</ToggleButton>
          <ToggleButton value="YTD">YTD</ToggleButton>
          <ToggleButton value="1Y">1Y</ToggleButton>
          <ToggleButton value="5Y">5Y</ToggleButton>
          <ToggleButton value="10Y">10Y</ToggleButton>
          <ToggleButton value="MAX">MAX</ToggleButton>
        </ToggleButtonGroup>
        <div>From:</div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id="date-from"
            format="MM/dd/yyyy"
            value={dateFrom}
            onChange={handleSelectDateFrom}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
        <div>To:</div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id="date-to"
            format="MM/dd/yyyy"
            value={dateTo}
            onChange={handleSelectDateTo}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
        <div className="select-container">
          <div>Frequency:</div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={frequency}
            onChange={handleChangeFrequency}
          >
            <MenuItem value={10}>5 min</MenuItem>
            <MenuItem value={20}>15 min</MenuItem>
            <MenuItem value={30}>30 min</MenuItem>
            <MenuItem value={30}>1 h</MenuItem>
          </Select>
        </div>
      </div>

      <Card className="small-chart-component">
        <div className="rounded-bottom overflow-hidden">
          <Chart
            options={chartsSmall1COptions}
            series={chartSeries}
            labels={chartLabels}
            type="area"
            height={chartHeight}
          />
        </div>
      </Card>
    </Grid>
  )
}

BigChartComponent.propTypes = {
  bgColor: PropTypes.string,
  chartClassName: PropTypes.string,
  chartcomponentClassName: PropTypes.string,
}

export default BigChartComponent
