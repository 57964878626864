import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// STYLE
import '../Preferences.scss'

// COMPONENTS
const SymbolPreview = ({
  symbol = {
    name: '',
    price: 0,
    priceChange: 0,
    pricePercentageChange: 0,
  },
}) => {
  return (
    <div className="symbol-preview-container">
      <div
        className={classNames(
          'symbol-preview-divider',
          symbol.priceChange > 0 ? 'gainer' : 'decliner',
        )}
      />
      <div className="symbol-preview-content">
        <div className="symbol-preview-name">{symbol.name}</div>
        <div className="symbol-preview-price">
          {' '}
          {Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(symbol.price)}
        </div>
        <div
          className={classNames(
            'symbol-preview-changes',
            symbol.priceChange > 0 ? 'gainer' : 'decliner',
          )}
        >
          <div>
            {Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(symbol.priceChange)}
          </div>
          <div>{symbol.pricePercentageChange}%</div>
        </div>
      </div>
    </div>
  )
}

SymbolPreview.propTypes = {
  symbol: PropTypes.object,
}

export default SymbolPreview
