import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// MATERIAL-UI
import { Grid } from '@material-ui/core'

// STYLE
import './Footer.scss'

// ASSETS

// COMPONENTS
import Logo from 'assets/logos/NaframLogo.svg'
import FacebookLogo from 'assets/logos/FacebookLogo.svg'
import TwitterLogo from 'assets/logos/TwitterLogo.svg'
import LinkedInLogo from 'assets/logos/LinkedInLogo.svg'

const Footer = () => {
  return (
    <Grid container className="footer-container">
      <Grid item xs={4} sm={4} className="footer-logo-container">
        <Grid item xs={12}>
          <img src={Logo} alt={''} className="footer-logo" />
        </Grid>
        <Grid item xs={12} className="footer-find-us-on">
          Find Us On
        </Grid>
        <Grid item xs={12} className="footer-social-networks-container">
          <img src={FacebookLogo} alt={''} className="social-network-logo" />
          <img src={TwitterLogo} alt={''} className="social-network-logo" />
          <img src={LinkedInLogo} alt={''} className="social-network-logo" />
        </Grid>
      </Grid>
      <Grid item xs={8} sm={8} className="footer-content">
        <Grid item xs={3}>
          <div className="footer-title">What we do</div>
          <div className="footer-link">For Investors</div>
          <div className="footer-link">For Advisors</div>
          <div className="footer-link">For Retirement Professionals</div>
        </Grid>
        <Grid item xs={3}>
          <div className="footer-title">About us</div>
          <div className="footer-link">Our story</div>
          <div className="footer-link">Our Signature Mark</div>
          <div className="footer-link">Careers </div>
        </Grid>
        <Grid item xs={3}>
          <div className="footer-title">Connect With Us</div>
          <div className="footer-link">Sales: +1 234 567 8900</div>
          <div className="footer-link">Global Contracts</div>
          <div className="footer-link">Advertising Opportunities</div>
          <div className="footer-link">Discussion Forums</div>
        </Grid>
        <Grid item xs={3}>
          <div className="footer-title">Get Help</div>
          <div className="footer-link">Help Center </div>
          <div className="footer-link">Support: +1 234 567 8900 </div>
          <div className="footer-link">support@nafram.com </div>
        </Grid>
      </Grid>
      <Grid item xs={12} className="footer-content">
        <Grid item xs={7} className="footer-link">
          This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service
          apply.
        </Grid>
        <Grid item xs={5} className="footer-buttons-container">
          <div className="footer-link">Terms of Use</div>
          <div className="footer-link">Privacy Policy</div>
          <div className="footer-link">Disclosures</div>
          <div className="footer-link">Cookies</div>
          <div className="footer-link">Corrections</div>
        </Grid>
      </Grid>
    </Grid>
  )
}

Footer.propTypes = {}

export default Footer
