export const markets = [
  {
    symbol: 'YFL',
    name: 'Luxury residential',
    lastPrice: 99.89,
    change: 9.69,
    changePrecentage: +10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'LRS',
    name: 'Warehouse pack',
    lastPrice: 99.89,
    change: -9.69,
    changePrecentage: -10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: -9.69,
    changePrecentage: -10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: -9.69,
    changePrecentage: -10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: -9.69,
    changePrecentage: -10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: 9.69,
    changePrecentage: +10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
]

export const gainers = [
  {
    symbol: 'YFL',
    name: 'Luxury residential',
    lastPrice: 99.89,
    change: 9.69,
    changePrecentage: +10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: 9.12,
    changePrecentage: 10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: 9.12,
    changePrecentage: 10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: 9.12,
    changePrecentage: 20.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: 9.12,
    changePrecentage: 2.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: 9.69,
    changePrecentage: +10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
]

export const decliners = [
  {
    symbol: 'YFL',
    name: 'Luxury residential',
    lastPrice: 99.89,
    change: -9.69,
    changePrecentage: -10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: -9.69,
    changePrecentage: -10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: -9.69,
    changePrecentage: -10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: -9.69,
    changePrecentage: -10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: -9.69,
    changePrecentage: -10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
  {
    symbol: 'TWST',
    name: 'Twist Biosience Corporation',
    lastPrice: 99.89,
    change: -9.69,
    changePrecentage: -10.74,
    volume: 123321,
    averageVolume: 661123,
    marketCapitalization: 4123987123,
    high: 412,
    low: 512,
    weeksRangeLow: 50.02,
    weeksRangeHigh: 501.02,
    pERatio: null,
  },
]
