import React from 'react'

// STYLE
import './Screeners.scss'

// ASSETS

// MATERIAL-UI
import { Grid } from '@material-ui/core'

// COMPONENTS
import NewsSection from 'components/NewsSection/NewsSection'
import ScreenersTable from './components/ScreenersTable'

const Screeners = () => {
  return (
    <Grid className="page-container">
      <ScreenersTable title="My Screeners" />
      <NewsSection />
    </Grid>
  )
}

export default Screeners
