import React from 'react'

// STYLE
import './LandingPageHeader.scss'

// COMPONENTS

// ASSETS

const bigtextLines = [
  'Real estate cannot be lost or stolen, nor can it be carried away.',
  'Purchased with common sense, paid for in full, and  ',
  'managed',
  ' with reasonable care, it is about the safest investment in the world',
]

const smallTextLine = 'Don’t wait to buy real estate, buy real estate and wait.'

const newsLine = 'Stocks Are Falling For 2 Reasons, But The End Is In Sight'

const LandingPageHeader = () => {
  return (
    <div className="landing-page-header">
      <div className="photo-container">
        <div className="landing-page-text">
          {bigtextLines.map((item, index) => (
            <h1 key={`text-line-${index}`}>{item}</h1>
          ))}
          <h2>{smallTextLine}</h2>
        </div>
      </div>

      <div className="news-line">
        <span className="news-text">{newsLine}</span>
        <span className="view-more">View More ></span>
      </div>
    </div>
  )
}

export default LandingPageHeader
