import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

// STYLE
import './News.scss'

// COMPONENTS
import MainNews from './components/MainNews'
import Brief from './components/MorningBrief'
import TrendingNow from './components/TrendingNow'
import NewsSection from 'components/NewsSection/NewsSection'

const NewsEntry = () => {
  const { category } = useParams()
  const [title, setTitle] = useState(null)

  useEffect(() => {
    switch (category) {
      case 'all-news':
      case 'morning-brief':
        setTitle('morning brief')
        break
      case 'specialist-opinion':
        setTitle('specialist opinion')
        break
      case 'global':
        setTitle('global')
        break
      case 'regional':
        setTitle('regional')
        break
      case 'local':
        setTitle('local')
        break
      case 'industrial':
        setTitle('industrial')
        break
      case 'residential':
        setTitle('residential')
        break
      case 'office':
        setTitle('office')
        break
      case 'geopolitic':
        setTitle('geopolitic')
        break
      case 'future-predictions':
        setTitle('future predictions')
        break
      case 'economy-news':
        setTitle('economy news')
        break
      default:
    }
  }, [category])

  return (
    <div className="page-container">
      <MainNews />
      <Brief title={title} />
      {category && category === 'all-news' && <TrendingNow />}
      {category && category === 'all-news' && <NewsSection />}
    </div>
  )
}

export default NewsEntry
