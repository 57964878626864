import { Grid } from '@material-ui/core'
import React from 'react'
import moment from 'moment'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './TrendingNow.scss'

// ASSETS
import Photo from 'assets/images/TrendingNowCardPhoto.svg'

// MOCKS
let sectionNews = [
  {
    title: 'America’s malls under pressure: CBL, Pennsylvania REIT, file for bankruptcy',
    content:
      'Mall owners CBL & Associates and r Pennsylvania Real Estate Investment Trust have filed for Chapter 11 bankruptcy protection, highlighting the pressures ...',
    date: '2021-08-12T13:00:00Z',
  },
  {
    title: 'America’s malls under pressure: CBL, Pennsylvania REIT, file for bankruptcy',
    content:
      'Mall owners CBL & Associates and r Pennsylvania Real Estate Investment Trust have filed for Chapter 11 bankruptcy protection, highlighting the pressures ...',
    date: '2021-08-12T13:00:00Z',
  },
  {
    title: 'America’s malls under pressure: CBL, Pennsylvania REIT, file for bankruptcy',
    content:
      'Mall owners CBL & Associates and r Pennsylvania Real Estate Investment Trust have filed for Chapter 11 bankruptcy protection, highlighting the pressures ...',
    date: '2021-08-12T13:00:00Z',
  },
]

// COMPONENTS
const TrendingNowCard = ({ title = null }) => {
  return (
    <Grid container className="trending-now-card-container">
      <Grid item xs={5} className="trending-now-card-left-section">
        <div className="trending-now-card-title">{title}</div>
        <div className="trending-now-card-photo-container">
          <img src={Photo} alt="" className="trending-now-card-photo" />
        </div>
        <div className="trending-now-card-view-more">View More ></div>
      </Grid>
      <Grid item xs={7} className="trending-now-card-right-section">
        {sectionNews.map((item, index) => (
          <Grid
            item
            xs={12}
            className="trending-now-card-news-container"
            key={`section-news-${item.date}-${index}`}
          >
            <div className="trending-now-card-news-title">{item.title}</div>
            <div className="trending-now-card-news-date">{moment(item.date).fromNow()}</div>
            <div className="trending-now-card-news-content">
              {item.content.length > 150 ? `${item.content.substring(0, 150)}...` : item.content}
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

TrendingNowCard.propTypes = {
  title: PropTypes.string,
}

const TrendingNow = () => {
  return (
    <Grid container className="trending-now-container">
      <Grid item xs={12} className="trending-now-title-container">
        <span className="trending-now-title">Trending Now</span>
      </Grid>
      <Grid container className="trending-now-cards-container" direction="row">
        <Grid item xs={6}>
          <TrendingNowCard title="World Market" />
          <TrendingNowCard title="Industrial" />
          <TrendingNowCard title="Office" />
          <TrendingNowCard title="Residential" />
        </Grid>
        <Grid item xs={6}>
          <TrendingNowCard title="Stocks" />
          <TrendingNowCard title="Economy" />
          <TrendingNowCard title="Future Prediction" />
          <TrendingNowCard title="Geopolitic" />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TrendingNow
