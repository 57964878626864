import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import '../Summary.scss'

// MATERIAL-UI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'

const labels = [
  ['Previous Close', 'Open', 'Bid', 'Ask'],
  ["Day's Range", '52 Week Range', 'Volume', 'Average Volume'],
  ['Market Capitalization', 'Beta (5Y Monthly)', 'PE Ratio (TTM)', 'EPS (TTM)'],
  ['Earnings Date', 'Forward Dividend & Yield', 'Ex-DIvidend Date', '1y Target Est'],
]

const SummaryTable = ({ data = [] }) => {
  return (
    <Grid container className="summary-table-container">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {labels[0].map(item => (
                <TableCell key={`${item}`}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>412</TableCell>
              <TableCell>123</TableCell>
              <TableCell>123</TableCell>
              <TableCell>123</TableCell>
            </TableRow>
          </TableBody>

          <TableHead>
            <TableRow>
              {labels[1].map(item => (
                <TableCell key={`${item}`}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={`${labels[1][0]}`}>
              <TableCell>125</TableCell>
              <TableCell>255,13 - 289,11</TableCell>
              <TableCell>30,123</TableCell>
              <TableCell>111,212</TableCell>
            </TableRow>
          </TableBody>

          <TableHead>
            <TableRow>
              {labels[2].map(item => (
                <TableCell key={`${item}`}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={`${labels[2][0]}`}>
              <TableCell>15,01M</TableCell>
              <TableCell>0,97</TableCell>
              <TableCell>60,12</TableCell>
              <TableCell>7,94</TableCell>
            </TableRow>
          </TableBody>

          <TableHead>
            <TableRow>
              {labels[3].map(item => (
                <TableCell key={`${item}`}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={`${labels[3][0]}`}>
              <TableCell className="no-border">Dec 08,2020</TableCell>
              <TableCell className="no-border">N/A</TableCell>
              <TableCell className="no-border">Mar 23, 2005</TableCell>
              <TableCell className="no-border">552,03</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

SummaryTable.propTypes = {
  data: PropTypes.array,
}

export default SummaryTable
