import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

// STYLE
import './SavedNewsOrSolutions.scss'

// ASSETS

// MATERIAL-UI
import { Grid } from '@material-ui/core'

// COMPONENTS
import NewsSection from 'components/NewsSection/NewsSection'
import BriefCard from 'components/BriefCard/BriefCard'

const SavedNewsOrSolutions = () => {
  const { category } = useParams()
  const [title, setTitle] = useState(null)

  useEffect(() => {
    switch (category) {
      case 'news':
        setTitle('Saved News')
        break
      case 'solutions':
        setTitle('Saved Solutions')
        break
      default:
    }
  }, [category])

  return (
    <Grid className="page-container">
      <Grid container className="insights-content-container">
        <Grid item xs={12} className="insights-content-title-container">
          <span className="insights-content-title">{title}</span>
        </Grid>
        <Grid container className="brief-articles-container">
          <BriefCard />
          <BriefCard />
          <BriefCard />
          <BriefCard />
          <BriefCard />
          <BriefCard />
          <BriefCard />
          <BriefCard />
          <BriefCard />
        </Grid>
      </Grid>
      <NewsSection />
    </Grid>
  )
}

export default SavedNewsOrSolutions
