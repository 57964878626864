import React from 'react'

// STYLE
import './Watchlist.scss'

// ASSETS

// MATERIAL-UI
import { Grid } from '@material-ui/core'

// MOCKUPS
import { markets } from 'mocks/MarketMocks'

// COMPONENTS
import MarketTable from 'components/MarketTable/MarketTable'
import NewsSection from 'components/NewsSection/NewsSection'

const Watchlist = () => {
  return (
    <Grid className="portfolios-page-container">
      <MarketTable title="Watchlist" data={markets} />
      <NewsSection />
    </Grid>
  )
}

export default Watchlist
