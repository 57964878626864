export const dropdownListMarketMenu = [
  {
    identifier: 'REbS',
    path: '/preview-stocks',
    routes: [
      {
        title: 'Industrial',
        path: '/stocks/industrial',
      },
      {
        title: 'Office',
        path: '/stocks/office',
      },
      {
        title: 'Residential',
        path: '/stocks/residential',
      },
    ],
  },
  {
    identifier: 'EFTs',
    path: '/efts',
    routes: [
      {
        title: 'Industrial',
        path: '/efts/industrial',
      },
      {
        title: 'Office',
        path: '/efts/office',
      },
      {
        title: 'Residential',
        path: '/efts/residential',
      },
    ],
  },
  {
    identifier: '',
    routes: [
      {
        title: 'Indexes',
        path: '/indexes',
      },
      {
        title: 'Futures',
        path: '/futures',
      },
      {
        title: 'Currencies',
        path: '/currencies',
      },
      {
        title: 'Mutual Funds',
        path: '/mutual-funds',
      },
      {
        title: 'Commodities',
        path: '/commodities',
      },
      {
        title: 'Calendar events',
        path: '/calendar-events',
      },
      {
        title: 'Industries',
        path: '/industries',
      },
    ],
  },
]

export const dropdownListNewsMenu = [
  {
    identifier: '',
    routes: [
      {
        title: 'All news',
        path: '/news/all-news',
      },
      {
        title: 'Morning Brief',
        path: '/news/morning-brief',
      },
      {
        title: 'Specialist Opinion',
        path: '/news/specialist-opinion',
      },
      {
        title: 'Global',
        path: '/news/global',
      },
      {
        title: 'Regional',
        path: '/news/regional',
      },
      {
        title: 'Local',
        path: '/news/local',
      },
      {
        title: 'Industrial',
        path: '/news/industrial',
      },
      {
        title: 'Residential',
        path: '/news/residential',
      },
      {
        title: 'Office',
        path: '/news/office',
      },
      {
        title: 'Geopolitic',
        path: '/news/geopolitic',
      },
      {
        title: 'Future Predictions',
        path: '/news/future-predictions',
      },
      {
        title: 'Economy News',
        path: '/news/economy-news',
      },
    ],
  },
]

export const dropdownListInsightsMenu = [
  {
    identifier: '',
    routes: [
      {
        title: 'Portfolio Strategies',
        path: '/insights/portfolio-strategies',
      },
      {
        title: 'Buy',
        path: '/insights/buy',
      },
      {
        title: 'Sell',
        path: '/insights/sell',
      },
      {
        title: 'Rent',
        path: '/insights/rent',
      },
      {
        title: 'Stock Training',
        path: '/insights/stock-training',
      },
      {
        title: 'Retirement',
        path: '/insights/retirement',
      },
      {
        title: 'Small Investing',
        path: '/insights/small-investing',
      },
    ],
  },
]

export const dropdownListMyWorkMenu = [
  {
    identifier: '',
    routes: [
      {
        title: 'Portfolios',
        path: '/my-work/portfolios',
      },
      {
        title: 'Screeners',
        path: '/my-work/screeners',
      },
      {
        title: 'Watchlist',
        path: '/my-work/watchlist',
      },
      {
        title: 'Saved News',
        path: '/my-work/saved/news',
      },
      {
        title: 'Saved Solutions',
        path: '/my-work/saved/solutions',
      },
    ],
  },
]
