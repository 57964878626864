import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './ProfessionalsOpinionCard.scss'

// MATERIAL-UI
import { Grid } from '@material-ui/core'

// ASSETS
import ProfessionalsPhoto from 'assets/images/ProfessionalsPhoto.svg'

const content =
  'Acouple of months ago, before the stock market woke up to the fact that there was a third major wave of the pandemic and realized that maybe Donald Trump’s continual assertions that we had “turned the corner” and that a vaccine was coming soon to a...'

const ProfessionalsOpinionCard = () => {
  return (
    <Grid className="professionals-opinion-container">
      <Grid className="professionals-opinion-person-container">
        <div className="professionals-opinion-image-container">
          <img src={ProfessionalsPhoto} alt={''} className="professionals-opinion-photo" />
          <div className="person-name">Julie Holland</div>
        </div>
        <div className="professionals-opinion-person-position">Industrial Sector Expert</div>
        <div className="view-profile">View Profile</div>
      </Grid>
      <Grid className="professionals-opinion-content">
        <div className="profesionals-opinion-article">
          <div className="article-title">Now We Know Why Big Tech Led the Charge in Stocks</div>
          <div className="article-content">
            {content.length > 200 ? `${content.substring(0, 200)}...` : content}
          </div>
          <div className="article-date">OCT 30 2020</div>
          <div className="view-more">View More ></div>
        </div>
        <div className="profesionals-opinion-article">
          <div className="article-title">Now We Know Why Big Tech Led the Charge in Stocks</div>
          <div className="article-content">
            {content.length > 200 ? `${content.substring(0, 200)}...` : content}
          </div>
          <div className="article-date">OCT 30 2020</div>
          <div className="view-more">View More ></div>
        </div>
        <div className="profesionals-opinion-article">
          <div className="article-title">Now We Know Why Big Tech Led the Charge in Stocks</div>
          <div className="article-content">
            {content.length > 200 ? `${content.substring(0, 200)}...` : content}
          </div>
          <div className="article-date">OCT 30 2020</div>
          <div className="view-more">View More ></div>
        </div>
      </Grid>
    </Grid>
  )
}

ProfessionalsOpinionCard.propTypes = {}

export default ProfessionalsOpinionCard
