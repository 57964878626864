import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

// STYLE
import './Stocks.scss'

// COMPONENTS
import MarketTable from 'components/MarketTable/MarketTable'
import NewsSection from 'components/NewsSection/NewsSection'

// MOCKS
import { markets, gainers, decliners } from 'mocks/MarketMocks'

const Stocks = () => {
  const { category } = useParams()

  const [pageProps, setPageProps] = useState({
    data: [],
    title: '',
    secondaryTitle: '',
    viewPagination: false,
  })

  useEffect(() => {
    // TOOD: backend must return data with only 1 level
    switch (category) {
      case 'gainers':
        setPageProps({
          data: [...gainers],
          title: 'Gainers',
          secondaryTitle: 'Top Gainers',
          viewPagination: false,
        })
        break
      case 'decliners':
        setPageProps({
          data: [...decliners],
          title: 'Decliners',
          secondaryTitle: 'Top Decliners',
          viewPagination: false,
        })
        break
      case 'share-volume':
        setPageProps({
          data: [...markets],
          title: 'Most active by share volume',
          secondaryTitle: '',
          viewPagination: false,
        })
        break
      case 'dollar-volume':
        setPageProps({
          data: [...markets],
          title: 'Most active by dollar volume',
          secondaryTitle: '',
          viewPagination: false,
        })
        break
      case 'all-markets':
        setPageProps({
          data: [...markets],
          title: 'Global market activity',
          secondaryTitle: '',
          viewPagination: true,
        })
        break
      case 'industrial':
        setPageProps({
          data: [...decliners],
          title: 'Industrial',
          secondaryTitle: '',
          viewPagination: true,
        })
        break
      case 'office':
        setPageProps({
          data: [...markets],
          title: 'Office',
          secondaryTitle: '',
          viewPagination: true,
        })
        break
      case 'residential':
        setPageProps({
          data: [...markets],
          title: 'Residential',
          secondaryTitle: '',
          viewPagination: true,
        })
        break
      default:
    }
  }, [category])

  return (
    <div className="page-container">
      <div className="stocks-page-title-container">
        <div className="stocks-page-title">Industrial Stock Market Preview</div>
      </div>
      <MarketTable
        title={pageProps.title}
        secondaryTitle={pageProps.secondaryTitle}
        data={pageProps.data}
        viewPagination={pageProps.viewPagination}
      />
      <NewsSection />
    </div>
  )
}

export default Stocks
