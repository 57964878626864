import React from 'react'
import { useParams } from 'react-router'
import moment from 'moment'

// STYLE
import './Insights.scss'

// MATERIAL-UI
import { Grid } from '@material-ui/core'

// ASSETS
import FacebookLogo from 'assets/logos/FacebookLogo'
import TwitterLogo from 'assets/logos/TwitterLogo'
import LinkedInLogo from 'assets/logos/LinkedInLogo'

// COMPONENTS

let title = 'Understanding Reflation—and Why It Matters'
let creationDate = '2021-08-12T13:00:00Z'
let updateDate = '2021-08-12T13:00:00Z'
let author = {
  name: 'Jerry Foster',
  position: 'Real Estate Expert',
  contact: ['@in/JerryFoster', '@in'],
  profileImage:
    'https://qph.fs.quoracdn.net/main-thumb-1278318002-200-ydzfegagslcexelzgsnplcklfkienzfr.jpeg',
}
let image =
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS5ujsDvRWuDvG7E6eBPxIdVC60vg5PP4aiuA&usqp=CAU'
let content =
  " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."

const InsightsEntry = () => {
  // eslint-disable-next-line
  const { entryId } = useParams()

  return (
    <div className="page-container">
      <Grid container className="insights-entry-container" direction="column">
        <div className="insights-entry-title">{title}</div>
        <div className="insights-entry-date-container">
          <div className="insights-entry-date">
            <span className="insights-entry-date-entry">
              PUBLISHED {moment(creationDate).format('ddd, MMM DD YYYY - h:mm A')}
            </span>
            <span className="insights-entry-date-entry">
              UPDATED {moment(updateDate).format('ddd, MMM DD YYYY - h:mm A')}
            </span>
          </div>
          <div className="insights-entry-social-network-container">
            <span>SHARE</span>
            <FacebookLogo fill="#0F1321" className="insights-entry-social-network-logo" />
            <TwitterLogo fill="#0F1321" className="insights-entry-social-network-logo" />
            <LinkedInLogo fill="#0F1321" className="insights-entry-social-network-logo" />
          </div>
        </div>
        <div className="insights-entry-author-container">
          <img src={`${author.profileImage}`} alt="" className="insights-entry-author-photo" />
          <div className="insights-entry-author-data">
            <span className="insights-entry-author-name">{author.name}</span>
            <span className="insights-entry-author-position">{author.position}</span>
            {author.contact.map((item, index) => (
              <span className="insights-entry-author-contact" key={`author-contact-${index}`}>
                {item}
              </span>
            ))}
          </div>
        </div>
        <div className="insights-entry-content-container">
          <div className="insights-entry-content-image-container">
            <img src={`${image}`} alt="" className="insights-entry-content-image" />
          </div>
          <div className="insights-entry-content">{content}</div>
        </div>
      </Grid>
    </div>
  )
}

export default InsightsEntry
