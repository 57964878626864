import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './PropertyProfile.scss'

// MATERIAL-UI
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'

const HoldersTable = ({ data = [] }) => {
  return (
    <Grid container className="holders-table-container">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="table-title">Holder</TableCell>
              <TableCell>Shares</TableCell>
              <TableCell>Date Reported</TableCell>
              <TableCell>%Out</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={`${row.name}_${index}`}>
                <TableCell component="th" scope="row">
                  {row.holder}
                </TableCell>
                <TableCell>{row.shares}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.out}</TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

HoldersTable.propTypes = {
  data: PropTypes.array,
}

export default HoldersTable
