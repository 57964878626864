import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

// STYLE
import './Screeners.scss'

// ASSETS
import Back from 'assets/icons/BackIcon.svg'

// MATERIAL-UI
import { Grid, Select, MenuItem, TextField, makeStyles } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

// MOCKUPS
import { markets } from 'mocks/MarketMocks'

// COMPONENTS
import NewsSection from 'components/NewsSection/NewsSection'
import MarketTable from 'components/MarketTable/MarketTable'

// CONSTANTS
import regions from 'constants/usaRegions'
import sectors from 'constants/sectors'
import industries from 'constants/industries'

const screenerDetails = [
  { label: 'Region', value: 'United States' },
  { label: 'Market Cap (Intraday)', value: 'Small cap' },
  { label: 'Price (Intraday)', value: 'Greater than 11$' },
]

const SelectedScreener = () => {
  const history = useHistory()
  const classes = useStyles()

  const [editScreenerVisible, setEditScreenerVisible] = useState(false)
  const [priceOption, setPriceOption] = useState('greater')
  const [currency, setCurrency] = useState('usd')
  const [priceValue, setPriceValue] = useState(0)
  const [marketCapOption, setMarketCapOption] = useState('small')
  const [region, setRegion] = useState('Alabama')
  const [sector, setSector] = useState('')
  const [industry, setIndustry] = useState('')

  const handleClickOnBack = () => {
    history.push(`/my-work/screeners`)
  }

  const handleSelectPriceOption = event => {
    event.preventDefault()
    setPriceOption(event.target.value)
    event.stopPropagation()
  }

  const handleSelectCurrency = event => {
    setCurrency(event.target.value)
  }

  const handleChangePriceValue = event => {
    setPriceValue(event.target.value)
  }

  const handleChangeMarketCapOption = (event, newAlignment) => {
    setMarketCapOption(newAlignment)
  }

  const handleChangeRegion = event => {
    setRegion(event.target.value)
  }

  const handleChangeSector = event => {
    setSector(event.target.value)
  }

  const handleChangeIndustry = event => {
    setIndustry(event.target.value)
  }

  return (
    <Grid className="selected-screener-container">
      <div className="selected-screener-title">Applied Screener</div>
      <div className="selected-screener-name">
        <img className="back-button" src={Back} alt="" onClick={handleClickOnBack} />
        <div>Screener name 1</div>
      </div>
      <div className="selected-screener-details">
        {screenerDetails.map((item, index) => (
          <div key={`screener-details-${index}`} className="row">
            <div className="selected-screener-details-label">{item.label}:</div>
            <div className="selected-screener-details-value">{item.value}</div>
          </div>
        ))}
      </div>
      {!editScreenerVisible && (
        <div className="selected-screener-edit-button" onClick={() => setEditScreenerVisible(true)}>
          Edit Screener
        </div>
      )}
      {editScreenerVisible && (
        <div className="selected-screener-edit-container">
          <div className="selected-screener-edit-label">Screener name</div>
          <div className="selected-screener-edit-value">
            <TextField
              className={classNames('select', 'text-field', 'big-variant')}
              fullWidth
              name="text"
              type="text"
              // value={priceValue || ''}
              // onChange={handleChangePriceValue}
            />
          </div>
          <div className="selected-screener-edit-label">Price (Intraday)</div>
          <div className="selected-screener-edit-value">
            <Select
              className="select"
              value={priceOption}
              onChange={handleSelectPriceOption}
              MenuProps={{
                classes: {
                  paper: classes.dropdownStyle,
                  root: classes.root,
                },
                disableScrollLock: true,
              }}
            >
              <MenuItem value={`greater`}>Greater than </MenuItem>
              <MenuItem value={`less`}>Less than</MenuItem>
              <MenuItem value={`equals`}>Equals</MenuItem>
              <MenuItem value={`between`}>Between</MenuItem>
            </Select>
            <TextField
              className={classNames('select', 'text-field')}
              fullWidth
              name="text"
              type="number"
              placeholder="Type value"
              value={priceValue || ''}
              onChange={handleChangePriceValue}
            />
            <Select
              className={classNames('select', 'small-variant')}
              value={currency}
              onChange={handleSelectCurrency}
              MenuProps={{
                classes: { paper: classes.dropdownStyle },
              }}
            >
              <MenuItem value={`usd`}>USD </MenuItem>
              <MenuItem value={`gbp`}>GBP</MenuItem>
              <MenuItem value={`eur`}>EUR</MenuItem>
            </Select>
          </div>
          <div className="selected-screener-edit-label">Market Cap (Intraday)</div>
          <div className="selected-screener-edit-value">
            <ToggleButtonGroup
              value={marketCapOption}
              exclusive
              onChange={handleChangeMarketCapOption}
              aria-label="text alignment"
            >
              <ToggleButton value="small">Small Cap</ToggleButton>
              <ToggleButton value="mid">Mid Cap</ToggleButton>
              <ToggleButton value="large">Large Cap</ToggleButton>
              <ToggleButton value="mega">Mega Cap</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="selected-screener-edit-label">Region</div>
          <div className="selected-screener-edit-value">
            <Select
              className={classNames('select', 'big-variant')}
              value={region}
              onChange={handleChangeRegion}
              MenuProps={{
                classes: { paper: classes.dropdownStyle },
              }}
            >
              {regions.map((item, index) => (
                <MenuItem value={`${item.name}`} key={`regions--${index}`}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="selected-screener-edit-label">Sector</div>
          <div className="selected-screener-edit-value">
            <Select
              className={classNames('select', 'big-variant')}
              value={sector}
              onChange={handleChangeSector}
              MenuProps={{
                classes: { paper: classes.dropdownStyle },
              }}
            >
              {sectors.map((item, index) => (
                <MenuItem value={`${item}`} key={`regions--${index}`}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="selected-screener-edit-label">Industry</div>
          <div className="selected-screener-edit-value">
            <Select
              className={classNames('select', 'big-variant')}
              value={industry}
              onChange={handleChangeIndustry}
              MenuProps={{
                classes: { paper: classes.dropdownStyle },
              }}
            >
              {industries.map((item, index) => (
                <MenuItem value={`${item}`} key={`regions--${index}`}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="selected-screener-edit-buttons-container">
            <div
              className="selected-screener-edit-button"
              onClick={() => setEditScreenerVisible(false)}
            >
              Save
            </div>
            <div
              className={classNames('selected-screener-edit-button', 'cancel-button')}
              onClick={() => setEditScreenerVisible(false)}
            >
              Cancel
            </div>
          </div>
          <div className="selected-screener-edit-results-container">
            <div className="selected-secreener-edit-results-label">Results:</div>
            <div className="selected-secreener-edit-results-value">12512</div>
          </div>
        </div>
      )}
    </Grid>
  )
}

const AppliedScreener = () => {
  return (
    <Grid className="applied-screener-page-container">
      <SelectedScreener />
      <MarketTable data={markets} />
      <NewsSection />
    </Grid>
  )
}

const useStyles = makeStyles({
  dropdownStyle: {
    '& .MuiListItem-root': {
      fontFamily: 'Montserrat',
      fontSize: '15px',
      color: '#0c2a4e',
    },
  },
  root: {
    position: 'absolute !important',
  },
})

export default AppliedScreener
