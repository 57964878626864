import * as React from 'react'
import PropTypes from 'prop-types'

function FacebookLogo({ fill = '', ...others }) {
  return (
    <svg
      data-name="Icon: Facebook"
      xmlns="http://www.w3.org/2000/svg"
      width={16.533}
      height={24}
      viewBox="0 0 16.533 24"
      {...others}
    >
      <path data-name="Rectangle 1458" fill="none" d="M0 0H16V16H0z" />
      <path
        data-name="Path 1"
        d="M88.133 24V13.067h3.733L92.4 8.8h-4.267V6.133c0-1.2.4-2.133 2.133-2.133h2.267V.133C92 .133 90.667 0 89.2 0c-3.2 0-5.467 2-5.467 5.6v3.2H80v4.267h3.733V24z"
        transform="translate(-76)"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  )
}

FacebookLogo.propTypes = {
  fill: PropTypes.string,
}

export default FacebookLogo
