import React, { useState } from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'
import classNames from 'classnames'

// STYLE
import '../Header.scss'

// ASSETS
import Logo from 'assets/logos/MenuLogo.svg'
import estater from 'assets/estater.apk'

// MATERIAL-UI
import { TextField, Grid, Modal } from '@material-ui/core'
import { Link } from 'react-router-dom'

const Menu1 = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [showLoginModal, setShowLoginModal] = useState(false)

  return (
    <Grid container className="menu1-container">
      <Grid item xs={1} className="menu-subcontainer-1">
        <div className="menu-text-style1">FRING</div>
      </Grid>
      <Grid item xs={5} className="menu-subcontainer">

        <a href="https://apex.nafram.org/" target="_blank" className="menu-text-style2" rel="noopener noreferrer">
          Apex Origin
        </a>
        {/* <div className="menu-text-style2">Apex Origin</div> */}
        <a href="https://slingshot.nafram.org/" target="_blank" className="menu-text-style2" rel="noopener noreferrer">
          Slingshot
        </a>

        {/* <div className="menu-text-style2">Estater</div> */}
        <Link to={estater} target="_blank" className="menu-text-style2" download>Estater</Link>
      </Grid>
      <Grid item xs={6} className="menu-subcontainer-22">
        <div
          className={classNames('menu-text-style3', 'blue-text')}
          onClick={() => setShowRegisterModal(true)}
        >
          REGISTER
        </div>
        <div className="menu-text-style3" onClick={() => setShowLoginModal(true)}>
          LOG IN
        </div>
      </Grid>
      <Modal
        open={showRegisterModal}
        onClose={() => setShowRegisterModal(false)}
        className="modal-container"
        disableScrollLock={true}
      >
        <div className="register-login-modal">
          <div className="register-login-modal-title">
            <div>Welcome to</div>
            <div className="register-login-modal-logo-container">
              <img src={Logo} alt="" className="logo" />
            </div>
          </div>
          <div className="register-login-modal-subtitle">Create your account to continue</div>
          <div className="register-login-modal-text-fields-container">
            <TextField
              className="text-field"
              fullWidth
              name="email"
              type="text"
              placeholder="E-mail..."
            //   value={value || ''}
            //   onChange={e => handleSearch(e)}
            />
            <TextField
              className="text-field"
              fullWidth
              name="password"
              type="password"
              placeholder="Password..."
            //   value={value || ''}
            //   onChange={e => handleSearch(e)}
            />
            <TextField
              className="text-field"
              fullWidth
              name="phone-number"
              type="text"
              placeholder="Phone number..."
            //   value={value || ''}
            //   onChange={e => handleSearch(e)}
            />
            <TextField
              className="text-field"
              fullWidth
              name="country"
              type="text"
              placeholder="Country..."
            //   value={value || ''}
            //   onChange={e => handleSearch(e)}
            />
          </div>
          <div className="register-login-modal-button-container">
            {/* <div className="register-login-modal-button">Sign up</div> */}
            <button className="register-login-modal-button"
              onClick={() => setShowRegisterModal(false)}
            > Sign up </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        className="modal-container"
        disableScrollLock={true}
      >
        <div className="register-login-modal">
          <div className="register-login-modal-title">
            <div>Welcome to</div>
            <div className="register-login-modal-logo-container">
              <img src={Logo} alt="" className="logo" />
            </div>
          </div>
          <div className="register-login-modal-subtitle">Sing in to your account to continue</div>
          <div className="register-login-modal-text-fields-container">
            <TextField
              className="text-field"
              fullWidth
              name="email"
              type="text"
              placeholder="E-mail..."
            //   value={value || ''}
            //   onChange={e => handleSearch(e)}
            />
            <TextField
              className="text-field"
              fullWidth
              name="password"
              type="password"
              placeholder="Password..."
            //   value={value || ''}
            //   onChange={e => handleSearch(e)}
            />
          </div>
          <div className="register-login-modal-button-container">
            {/* <div className="register-login-modal-button">Sign in</div> */}
            <button className="register-login-modal-button"
              onClick={() => setShowLoginModal(false)}
            > Sign in </button>
          </div>
        </div>
      </Modal>
    </Grid>
  )
}

Menu1.propTypes = {}

export default Menu1
