import React, { useState } from 'react'

// STYLE
import './Portfolios.scss'

// ASSETS
import Photo from 'assets/images/PortfoliosPhoto.svg'

// MATERIAL-UI
import { Grid, TextField, InputAdornment, IconButton } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import PortfolioTable from './components/PortfolioTable'

// MOCKUPS
import { markets } from 'mocks/MarketMocks'
import NewsSection from 'components/NewsSection/NewsSection'
import classNames from 'classnames'

// COMPONENTS

const Portfolios = () => {
  const [isCreateExpanded, setIsCreateExpanded] = useState(false)

  const handleClickCreate = () => {
    setIsCreateExpanded(prevValue => !prevValue)
  }

  return (
    <Grid className="portfolios-page-container">
      <div className="portfolios-image-container">
        <img src={Photo} alt="" className="portfolios-image" />
      </div>
      <div className="portfolios-search-container">
        <TextField
          className="search-bar"
          fullWidth
          name="text"
          type="text"
          placeholder="Find portfolio"
          //   value={value || ''}
          //   onChange={e => handleSearch(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton aria-label="toggle password visibility">
                  <SearchIcon className="search-icon" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className="portfolios-create-portfolio-button-container">
          <div className="portfolios-create-portfolio-button-label" onClick={handleClickCreate}>
            + Create New Portfolio
          </div>
          {isCreateExpanded && (
            <div className="portfolios-create-portfolio-container">
              <div className="portfolios-create-portfolio-input">
                <TextField
                  className="create-portfolio"
                  fullWidth
                  name="text"
                  type="text"
                  placeholder="Add portfolio name"
                  //   value={value || ''}
                  //   onChange={e => handleSearch(e)}
                />
              </div>
              <div className="portfolios-create-portfolio-input">
                <TextField
                  className="create-portfolio"
                  fullWidth
                  name="text"
                  type="text"
                  placeholder="Chose Currency"
                  //   value={value || ''}
                  //   onChange={e => handleSearch(e)}
                />
              </div>
              <div className="portfolios-create-portfolio-input">
                <TextField
                  className={classNames('create-portfolio', 'text-area')}
                  fullWidth
                  name="text"
                  type="text"
                  placeholder="Add Description"
                  multiline={true}
                  //   value={value || ''}
                  //   onChange={e => handleSearch(e)}
                />
              </div>
              <div className="portfolios-create-portfolio-buttons-container">
                <div className="portfolios-create-portfolio-button">CREATE</div>
                <div className="portfolios-create-portfolio-button">CANCEL</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="portfolios-tables-container">
        <PortfolioTable title="Industrial Property Portfolio" data={markets} />
      </div>
      <NewsSection />
    </Grid>
  )
}

export default Portfolios
