import React, { useRef, useState } from 'react'
import classNames from 'classnames'

// STYLE
import './Preferences.scss'

// ASSETS
import DownArrow from 'assets/icons/DownArrow.svg'
import UpArrow from 'assets/icons/UpArrow.svg'

// MOCKS
import { symbols } from 'mocks/SymbolMocks'

// MATERIAL-UI
import { TextField, makeStyles, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

// COMPONENTS
import SymbolPreview from './components/SymbolPreview'

let options = [
  { title: 'TEST', label: 'TEST' },
  { title: 'TEST', label: 'TEST' },
  { title: 'TEST', label: 'TEST' },
  { title: 'TEST', label: 'TEST' },
  { title: 'TEST', label: 'TEST' },
]

const Preferences = () => {
  const dropdownRef = useRef(null)

  const classes = useStyles()

  const [expanded, setExpanded] = useState(false)
  const [isTextfieldVisible, setIsTextfieldVisible] = useState(false)

  const handleExpand = () => {
    setExpanded(prevValue => !prevValue)
  }

  const handleClickSave = () => {
    //TODO:
    setIsTextfieldVisible(false)
  }

  const handleClickCancel = () => {
    setIsTextfieldVisible(false)
  }

  return (
    <div className="preferences-container" ref={dropdownRef}>
      <div className="preferences-expander-button-container" onClick={handleExpand}>
        {expanded ? (
          <>
            <div className="preferences-expander-button-label">Preferences</div>
            <div className="preferences-expander-button-image">
              <img src={DownArrow} alt={''} />
            </div>
          </>
        ) : (
          <>
            <div className="preferences-expander-button-image">
              <img src={UpArrow} alt={''} />
            </div>
            <div className="preferences-expander-button-label">Preferences</div>
          </>
        )}
      </div>
      {expanded && (
        <div className="preferences-content-container">
          <div className="preferences-content-head">
            <div className="preferences-content-head-row">
              <div
                className="preferences-content-head-button"
                onClick={() => setIsTextfieldVisible(true)}
              >
                + ADD A SYMBOL
              </div>
              {isTextfieldVisible && (
                <div className="preferences-content-add-symbol-container">
                  <Autocomplete
                    className="autocomplete"
                    options={options}
                    getOptionLabel={option => option.title}
                    style={{ width: 300 }}
                    classes={{ paper: classes.paper }}
                    renderInput={params => <TextField {...params} className="search-bar" />}
                  />
                  <Button
                    className={classNames('preferences-content-add-symbol-button', 'save-button')}
                    onClick={handleClickSave}
                  >
                    Save
                  </Button>
                  <Button
                    className="preferences-content-add-symbol-button"
                    onClick={handleClickCancel}
                  >
                    Cancel
                  </Button>
                </div>
              )}
              <div className="preferences-content-head-button">EDIT</div>
              <div className="preferences-content-head-button">VIEW AS TABLE</div>
            </div>
            <div className="preferences-content-head-button">MY PORTFOLIO</div>
          </div>
          <div className="preferences-content-symbols-container">
            {symbols.map((item, index) => (
              <SymbolPreview symbol={item} key={`symbol--${index}`} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles({
  paper: {
    borderRadius: '0',
    backgroundColor: '#0c2a4e',
    color: '#ffffff',
    fontSize: '18px',
    border: '2px solid #3db9f8',
  },
})

Preferences.propTypes = {}

export default Preferences
