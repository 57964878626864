import React, { useState } from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './PortfolioTable.scss'

// ASSETS
import LinkIcon from 'assets/icons/LinkIcon.svg'
import PrintIcon from 'assets/icons/PrintIcon.svg'
import ImportIcon from 'assets/icons/ImportIcon.svg'
import ExportIcon from 'assets/icons/ExportIcon.svg'
import SettingsIcon from 'assets/icons/SettingsIcon.svg'
import SearchIcon from '@material-ui/icons/Search'

// MATERIAL-UI
import { TextField, InputAdornment, IconButton } from '@material-ui/core'

// COMPONENTS
import MenuTabs from 'components/MenuTabs/MenuTabs'

let tabs = [
  { value: 0, label: 'Summary', component: null },
  {
    value: 1,
    label: 'My holdings',
    component: null,
  },
  { value: 2, label: 'Progress', component: null },
  { value: 3, label: 'Characteristics', component: null },
  { value: 4, label: 'Risk Analysis', component: null },
  { value: 5, label: '+ New View', component: null },
]

const PortfolioTableHeader = ({ title = null }) => {
  const [isAddExpanded, setIsAddExpanded] = useState(false)

  const handleClickAdd = () => {
    setIsAddExpanded(prevValue => !prevValue)
  }

  return (
    <div className="portfolio-table-header">
      <div className="row">
        <div className="portfolio-table-header-title">{title}</div>
        <div className="portfolio-table-header-links-container">
          <div className="portfolio-table-header-link-container">
            <div>
              <img src={LinkIcon} alt="" className="portfolio-table-header-icon" />
            </div>
            <div className="portfolio-table-header-link-label">Link Broker</div>
          </div>
          <div className="portfolio-table-header-link-container">
            <div>
              <img src={PrintIcon} alt="" className="portfolio-table-header-icon" />
            </div>
            <div className="portfolio-table-header-link-label">Print</div>
          </div>
          <div className="portfolio-table-header-link-container">
            <div>
              <img src={ImportIcon} alt="" className="portfolio-table-header-icon" />
            </div>
            <div className="portfolio-table-header-link-label">Import</div>
          </div>
          <div className="portfolio-table-header-link-container">
            <div>
              <img src={ExportIcon} alt="" className="portfolio-table-header-icon" />
            </div>
            <div className="portfolio-table-header-link-label">Export</div>
          </div>
          <div className="portfolio-table-header-link-container">
            <div>
              <img src={SettingsIcon} alt="" className="portfolio-table-header-icon" />
            </div>
            <div className="portfolio-table-header-link-label">Settings</div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="menu-tabs">
          <MenuTabs tabs={tabs} />
        </div>
        <div className="portfolio-table-header-link-container">
          <div className="portfolio-table-header-link-label" onClick={handleClickAdd}>
            + Add Symbol
          </div>
          {isAddExpanded && (
            <div className="portfolio-add-container">
              <div className="porfolio-add-text-field">
                <TextField
                  className="search-bar"
                  fullWidth
                  name="text"
                  type="text"
                  placeholder="Type symbol name"
                  //   value={value || ''}
                  //   onChange={e => handleSearch(e)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton aria-label="toggle password visibility">
                          <SearchIcon className="search-icon" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="portfolio-add-container-text">Enter symbols or company names</div>
              <div className="portfolio-add-container-text">
                Tip: Use comma to separate multiple quotes
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

PortfolioTableHeader.propTypes = {
  title: PropTypes.string,
}

export default PortfolioTableHeader
