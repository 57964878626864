import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

// STYLE
import './MorningBrief.scss'

// COMPONENTS
import BriefCard from 'components/BriefCard/BriefCard'

const MorningBrief = ({ title = '' }) => {
  return (
    <Grid container className="morning-brief-container">
      <Grid item xs={12} className="morning-brief-title-container">
        <span className="morning-brief-title">{title}</span>
      </Grid>
      <Grid container className="brief-articles-container">
        <BriefCard />
        <BriefCard />
        <BriefCard />
      </Grid>
    </Grid>
  )
}

MorningBrief.propTypes = {
  title: PropTypes.string,
}

export default MorningBrief
