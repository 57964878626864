import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

// STYLE
import './Insights.scss'

// ASSETS
import { Grid } from '@material-ui/core'

// COMPONENTS
import BriefCard from 'components/BriefCard/BriefCard'

const Insights = () => {
  const { category } = useParams()
  const [title, setTitle] = useState(null)

  useEffect(() => {
    switch (category) {
      case 'portfolio-strategies':
        setTitle('portfolio strategies')
        break
      case 'buy':
        setTitle('buy')
        break
      case 'sell':
        setTitle('sell')
        break
      case 'rent':
        setTitle('rent')
        break
      case 'stock-training':
        setTitle('stock training')
        break
      case 'retirement':
        setTitle('retirement')
        break
      case 'small-investing':
        setTitle('small investing')
        break
      default:
    }
  }, [category])

  return (
    <div className="page-container">
      <Grid container className="insights-content-container">
        <Grid item xs={12} className="insights-content-title-container">
          <span className="insights-content-title">{title}</span>
        </Grid>
        <Grid container className="brief-articles-container">
          <BriefCard type="insights" />
          <BriefCard type="insights" />
          <BriefCard type="insights" />
          <BriefCard type="insights" />
          <BriefCard type="insights" />
          <BriefCard type="insights" />
          <BriefCard type="insights" />
          <BriefCard type="insights" />
          <BriefCard type="insights" />
        </Grid>
      </Grid>
    </div>
  )
}

export default Insights
