import * as React from 'react'
import PropTypes from 'prop-types'

function LinkedInLogo({ fill = '', ...others }) {
  return (
    <svg
      data-name="Icon: Facebook"
      xmlns="http://www.w3.org/2000/svg"
      width={16.533}
      height={24}
      viewBox="0 0 28 32"
      {...others}
    >
      <text
        transform="translate(0 25)"
        fill={fill}
        fontSize={26}
        fontFamily="Montserrat-Bold, Montserrat"
        fontWeight={700}
        letterSpacing="0.05em"
      >
        <tspan x={0} y={0}>
          {'in'}
        </tspan>
      </text>
    </svg>
  )
}

LinkedInLogo.propTypes = {
  fill: PropTypes.string,
}

export default LinkedInLogo
