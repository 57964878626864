import React from 'react'

// STYLE
import './Layout.scss'

// COMPONENTS
import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import LandingPageHeader from 'components/LandingPageHeader/LandingPageHeader'
import Preferences from 'components/Preferences/Preferences'

const SecondaryLayout = ({ children }) => {
  return (
    <div className="layout-container">
      <div className="layout-header">
        <Header />
        <LandingPageHeader />
      </div>
      <div className="layout-content">{children}</div>
      <div className="layout-footer">
        <Footer />
      </div>
      <Preferences />
    </div>
  )
}

export default SecondaryLayout
