export const investmentHighlights = [
  'The facility supports on-site rail access to quickly ship goods, and a short 5-minute drive to the airport allows for long-distance distribution.',
  "Robust population and OSU's top 10 production and operation program adds to a qualified labor pool.",
  'Columbus is one of the top distribution markets in the nation with access to 46% of the US population within a 10-hour truck drive.',
  'The city offers extremely low effective tax rates on both new labor-intensive and capital-intensive manufacturing facilities.',
]

export const aboutCompany =
  'Ideally situated 5 minutes from I-270 and only 5 minutes from Bolton Field Airport, this 418,787 square-foot manufacturing facility offers convenient transportation including direct, on-site rail access. The fact that Columbus is within reach of 46% of the US population and manufacturing capacity via a 10-hour truck drive underscores the location’s claim to being one of the top distributing markets in the country.  Businesses have the opportunity to take advantage of the city being among the nation’s top 10 locales with the lowest effective tax rates on both new labor-intensive and capital-intensive manufacturing facilities. In addition, organizations such as RAMTEC have proven willing to collaborate with and offer training programs and certifications to local manufacturing firms. When coupled with the fact that Ohio State’s Fisher College of Business ranked number 10 among MBA programs in production and operations, firms moving into the building will have access to a strong labor pool while also having training resources readily available to them.'

export const retail = [
  { name: 'CrossFit', type: 'Fitness', duration: '16 min walk' },
  { name: 'Sembro', type: 'Home Improvement', duration: '11 min walk' },
  { name: 'Officemax', type: 'Office Supply', duration: '12 min walk' },
  { name: 'UPS Freight', type: 'Business/Copy/Postal Services', duration: '18 min walk' },
  { name: 'CVS Pharmacy', type: 'Drug Store', duration: '8 min walk' },
  { name: 'Dollar General', type: 'Dollar/ Variety', duration: '16 min walk' },
]

export const hotels = [
  { name: 'CourtYard', noOfStars: '3 Stars', duration: '16 min walk' },
  { name: 'Hampton ', noOfStars: '3 Stars', duration: '12 min walk' },
  { name: 'DoubleTree', noOfStars: '5 Stars', duration: '12 min walk' },
  { name: 'Renaissance', noOfStars: '4 Stars', duration: '11 min walk' },
  { name: 'Sheraton Hotel', noOfStars: '3 Stars', duration: '19 min walk' },
]

export const transportation = [
  {
    name: 'John Glenn Columbus International Airport',
    type: 'Airport',
    distance: '19.0mi',
    duration: '31 min drive',
  },
  { name: 'Port Milwaukee', type: 'Freightport', distance: '19.0mi', duration: '31 min drive' },
  {
    name: 'NS BULK TRANSFER TERMINAL-COLUMBUS-OH',
    type: 'Railroad',
    distance: '19.0mi',
    duration: '31 min drive',
  },
]

export const keyExecutives = [
  { name: 'Mr. Shantanu Narayen	', title: 'Chairman, Pres & CEO' },
  { name: 'Dr. John E. Warnock	', title: 'Co-Founder & Independent Director' },
  { name: 'Dr. Charles M. Geschke	', title: 'Co-Founder & Director Emeritus' },
  { name: 'Mr. John F. Murphy	', title: 'Exec. VP & CFO' },
  { name: 'Mr. Abhay Parasnis	', title: 'Exec. VP of Strategy & Growth and CTO' },
  { name: 'Mr. Bryan Lamkin	', title: 'Exec. VP & Gen. Mang. of Digital Media' },
  { name: 'Mr. Shantanu Narayen	', title: 'Chairman, Pres & CEO' },
  { name: 'Dr. John E. Warnock	', title: 'Co-Founder & Independent Director' },
  { name: 'Dr. Charles M. Geschke	', title: 'Co-Founder & Director Emeritus' },
]

export const accessAndJaborForce = [
  { indicator: 'Total Population', value: '528,304' },
  { indicator: 'Total Labor Force', value: '322,123' },
  { indicator: 'Unemployment Rate', value: '3,24%' },
  { indicator: 'Median Household income', value: '$52,123' },
  { indicator: 'Warehouse Employees', value: '41,253' },
  { indicator: 'High School Education or Higher', value: '88,21%' },
]

export const institutionalHolders = [
  {
    holder: 'Vanguard Group, Inc. (The)',
    shares: '112,123',
    date: 'Jun 29,2020',
    out: '8,21%',
    value: '17,241,123,123',
  },
  {
    holder: 'Blackrock Inc',
    shares: '1,126,123',
    date: 'May 29,2020',
    out: '12,21%',
    value: '22,241,152,123',
  },
  {
    holder: 'FMR, LLC',
    shares: '82,991,127',
    date: 'Jun 29,2020',
    out: '8,21%',
    value: '1,242,123,111',
  },
  {
    holder: 'Vanguard Group, Inc. (The)',
    shares: '112,123',
    date: 'Jun 29,2020',
    out: '8,21%',
    value: '17,241,123,123',
  },
  {
    holder: 'Blackrock Inc',
    shares: '1,126,123',
    date: 'May 29,2020',
    out: '12,21%',
    value: '22,241,152,123',
  },
  {
    holder: 'FMR, LLC',
    shares: '82,991,127',
    date: 'Jun 29,2020',
    out: '8,21%',
    value: '1,242,123,111',
  },
  {
    holder: 'Vanguard Group, Inc. (The)',
    shares: '112,123',
    date: 'Jun 29,2020',
    out: '8,21%',
    value: '17,241,123,123',
  },
  {
    holder: 'Blackrock Inc',
    shares: '1,126,123',
    date: 'May 29,2020',
    out: '12,21%',
    value: '22,241,152,123',
  },
  {
    holder: 'FMR, LLC',
    shares: '82,991,127',
    date: 'Jun 29,2020',
    out: '8,21%',
    value: '1,242,123,111',
  },
]
