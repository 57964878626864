import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

// STYLE
import './PortfolioTable.scss'

// ASSETS
import GainersArrow from 'assets/icons/GainersArrow.svg'
import DeclinersArrow from 'assets/icons/DeclinersArrow.svg'
import GreenChartPreview from 'assets/images/GreenChartPreview.svg'
import RedChartPreview from 'assets/images/RedChartPreview.svg'

// MATERIAL-UI
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'

// COMPONENTS
import PortfolioTableHeader from './PortfolioTableHeader'

let tableHead = [
  'Symbol',
  'Name',
  'Market Time',
  'Last Price',
  'Change',
  'Change Precentage',
  'Volume',
  'Average Volume (3 months)',
  'Market Capitalization',
  'High & Low Day Range',
  '52 weeks Range',
  'P/E Ratio',
  'Chart Preview',
]

const PortfolioTable = ({ data = [], title = null, type = null }) => {
  const history = useHistory()

  const handleNavigateToEntry = () => {
    history.push('/company-profile/symbol')
  }

  return (
    <TableContainer component={Paper} className="portfolio-table-container">
      <PortfolioTableHeader title={title} />
      <Table>
        <TableHead>
          <TableRow>
            {tableHead.map((item, index) => (
              <TableCell key={`${item}_${index}`}>{item}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map(row => (
              <TableRow key={`${row.symbol}_${Math.random()}`} onClick={handleNavigateToEntry}>
                <TableCell>
                  {row.change > 0 ? (
                    <img src={GainersArrow} alt={''} />
                  ) : (
                    <img src={DeclinersArrow} alt={''} />
                  )}{' '}
                  {row.symbol}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{`${moment().format('hh:mm a')}`}</TableCell>
                <TableCell>
                  {' '}
                  {Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(row.lastPrice)}
                </TableCell>
                <TableCell className={row.change > 0 ? `green-text` : `red-text`}>
                  {row.change > 0
                    ? `+${Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(row.change)}`
                    : ` ${Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(row.change)}`}
                </TableCell>
                <TableCell className={row.changePrecentage > 0 ? `green-text` : `red-text`}>
                  {row.changePrecentage > 0
                    ? `+${row.changePrecentage}%`
                    : `${row.changePrecentage}%`}
                </TableCell>
                <TableCell>{Intl.NumberFormat().format(row.volume)}</TableCell>
                <TableCell>{Intl.NumberFormat().format(row.averageVolume)}</TableCell>
                <TableCell>{Intl.NumberFormat().format(row.marketCapitalization)}</TableCell>
                <TableCell>
                  {Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(row.high)}{' '}
                  -{' '}
                  {Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(row.low)}
                </TableCell>
                <TableCell>
                  {row.weeksRangeLow} - {row.weeksRangeHigh}
                </TableCell>
                <TableCell>{row.pERatio || `N/A`}</TableCell>
                <TableCell>
                  {row.change > 0 ? (
                    <img src={GreenChartPreview} alt={''} />
                  ) : (
                    <img src={RedChartPreview} alt={''} />
                  )}{' '}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

PortfolioTable.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string,
}

export default PortfolioTable
