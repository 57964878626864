export const earningsEstimate = [
  { indicator: 'No. of Analysts', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: 'Avg. Estimate', currentQtr: 2.2, nextQrt: 20.12, currentYear: 11, nextYear: 5 },
  { indicator: 'Low Estimate', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: 'Highest Estimate', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: 'Year Ago EPS', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
]

export const earningsHistory = [
  { indicator: 'EPS Est.', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: 'EPS Actual.', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: 'Difference.', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  {
    indicator: 'Surprise % Est.',
    currentQtr: '22%',
    nextQrt: '12%',
    currentYear: '231%',
    nextYear: '222%',
  },
]

export const epsTrend = [
  { indicator: 'Current Estimate', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: '7 Days Ago', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: '30 Days Ago', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: '60 Days Ago', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: '90 Days Ago', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
]

export const epsRevisions = [
  { indicator: 'Up Last 7 Days', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: 'Up Last 30 Days', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: 'Down Last 7 Days', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
  { indicator: 'Down Last 30 Days', currentQtr: 22, nextQrt: 20, currentYear: 25, nextYear: 25 },
]

export const growthEstimates = [
  { indicator: 'Current Qrt.', crs: '16.20%', industry: 'N/A', sectors: 'N/A', sp500: 'N/A' },
  { indicator: 'Next Qrt', crs: '16.20%', industry: 'N/A', sectors: 'N/A', sp500: 'N/A' },
  { indicator: 'Current Year', crs: '16.20%', industry: 'N/A', sectors: 'N/A', sp500: 'N/A' },
  { indicator: 'Next Year', crs: '16.20%', industry: 'N/A', sectors: 'N/A', sp500: 'N/A' },
  {
    indicator: 'Next 5 Years (per annum)',
    crs: '16.20%',
    industry: 'N/A',
    sectors: 'N/A',
    sp500: 'N/A',
  },
  {
    indicator: 'Past 5 Years (per annum)',
    crs: '16.20%',
    industry: 'N/A',
    sectors: 'N/A',
    sp500: 'N/A',
  },
]
