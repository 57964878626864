import React from 'react'
import moment from 'moment'

// STYLE
import './LastestNewsCard.scss'

// ASSETS

// MATERIAL-UI
import { Grid } from '@material-ui/core'

let newsObj = {
  title:
    'Stocks making the biggest moves in the premarket: Clorox, Estee Lauder,  Dunkin’ Brands & more',
  text:
    'Take a look at some of the biggest movers in the premarket: Clorox (CLX) – The household products maker reported quarterly earnings of $3.22 per share, compared to a consensus estimate of $2.32 a share. Revenue also topped forecasts, as Clorox continued to benefit from purchases by homebound consumers amid...',
  postDate: '2021-08-12T13:00:00Z',
}

const LastestNewsCard = () => {
  return (
    <Grid container className="lastest-news-card-container">
      <div className="lastest-news-card-title">{newsObj.title}</div>
      <div className="lastest-news-card-content">{newsObj.text}</div>
      <div className="lastest-news-card-post-date">{moment(newsObj.postDate).fromNow()}</div>
    </Grid>
  )
}

export default LastestNewsCard
