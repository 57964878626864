import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './Summary.scss'

// MATERIAL-UI
import { Grid } from '@material-ui/core'

//COMPONENTS
import SummaryTable from './components/SummaryTable'

// ASSETS
import SmallChartComponent from 'components/SmallChartComponent/SmallChartComponent'

const Summary = () => {
  return (
    <Grid container className="menu-tab-container" direction="column" wrap="nowrap">
      <SmallChartComponent />
      <SummaryTable />
    </Grid>
  )
}

Summary.propTypes = {}

export default Summary
