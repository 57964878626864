import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import axios from 'axios'

// COMPONENTS
import Layout from 'components/Layouts/Layout'
import SecondaryLayout from 'components/Layouts/SecondaryLayout'
import NotFound from 'pages/NotFound/NotFound'
import CompanyProfile from 'pages/CompanyProfile/CompanyProfile'
import PreviewStocks from 'pages/Stocks/PreviewStocks'
import Stocks from 'pages/Stocks/Stocks'
import LandingPage from 'pages/LandingPage/LandingPage'
import Insights from 'pages/Insights/Insights'
import InsightsEntry from 'pages/Insights/InsightsEntry'
import NewsEntry from 'pages/News/NewsEntry'
import Portfolios from 'pages/Portfolios/Portfolios'
import Watchlist from 'pages/Watchlist/Watchlist'
import SavedNewsOrSolutions from 'pages/SavedNewsOrSolutions/SavedNewsOrSolutions'
import Screeners from 'pages/Screeners/Screeners'
import AppliedScreener from 'pages/Screeners/AppliedScreener'

const App = () => {
  const token = localStorage.getItem('token')
  const { pathname } = useLocation()

  useEffect(() => {
    // Logout user if token invalid
    axios.interceptors.response.use(
      response => {
        return response
      },
      async function(error) {
        if (
          'response' in error &&
          typeof error.response !== 'undefined' &&
          error.response.status === 401 &&
          (token === undefined || token === null)
        ) {
          // logout user if token is invalid / null / unauthorized
        }
        return Promise.reject(error)
      },
    )
  }, [token])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Switch>
      <Route exact path="/">
        <SecondaryLayout>
          <LandingPage />
        </SecondaryLayout>
      </Route>

      {/* MARKET MENU */}
      <Route exact path="/preview-stocks">
        <Layout>
          <PreviewStocks />
        </Layout>
      </Route>
      <Route exact path="/stocks/:category">
        <Layout>
          <Stocks />
        </Layout>
      </Route>
      <Route exact path="/company-profile/:symbol">
        <Layout>
          <CompanyProfile />
        </Layout>
      </Route>

      {/* NEWS MENU */}
      <Route exact path="/news/:category">
        <Layout>
          <NewsEntry />
        </Layout>
      </Route>

      {/* INSIGHTS MENU */}
      <Route exact path="/insights-entry/:entryId">
        <Layout>
          <InsightsEntry />
        </Layout>
      </Route>
      <Route exact path="/insights/:category">
        <Layout>
          <Insights />
        </Layout>
      </Route>

      {/* MY WORK MENU */}
      <Route exact path="/my-work/portfolios">
        <Layout>
          <Portfolios />
        </Layout>
      </Route>
      <Route exact path="/my-work/screeners/">
        <Layout>
          <Screeners />
        </Layout>
      </Route>
      <Route exact path="/my-work/screeners/:id">
        <Layout>
          <AppliedScreener />
        </Layout>
      </Route>
      <Route exact path="/my-work/watchlist">
        <Layout>
          <Watchlist />
        </Layout>
      </Route>
      <Route exact path="/my-work/saved/:category">
        <Layout>
          <SavedNewsOrSolutions />
        </Layout>
      </Route>

      <Route path="/404">
        <NotFound />
      </Route>
      <Redirect to="/404" />
    </Switch>
  )
}

export default App
