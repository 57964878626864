import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './Analysis.scss'

// MATERIAL-UI
import { Grid } from '@material-ui/core'

//COMPONENTS

// MOCKS
import {
  earningsEstimate,
  earningsHistory,
  epsTrend,
  epsRevisions,
  growthEstimates,
} from 'mocks/AnalysisMocks'
import AnalysisTable from './components/AnalysisTable'

const Analysis = () => {
  return (
    <Grid container className="menu-tab-container" direction="column" wrap="nowrap">
      <AnalysisTable data={earningsEstimate} title="Earnings Estimate" />
      <AnalysisTable data={earningsEstimate} title="Revenue Estimate" />
      <AnalysisTable data={earningsHistory} title="Earnings History" />
      <AnalysisTable data={epsTrend} title="EPS Trend" />
      <AnalysisTable data={epsRevisions} title="EPS Revisions" />
      <AnalysisTable data={growthEstimates} title="Growth Estimates" type="growth" />
    </Grid>
  )
}

Analysis.propTypes = {}

export default Analysis
