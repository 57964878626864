import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './Header.scss'

// COMPONENTS
import Menu1 from './components/Menu1'
import Menu2 from './components/Menu2'

const Header = () => {
  return (
    <div>
      <Menu1 />
      <Menu2 />
    </div>
  )
}

Header.propTypes = {}

export default Header
