import React, { useEffect, useRef, useState } from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

// STYLE
import '../Header.scss'

// ASSETS
import Logo from 'assets/logos/MenuLogo.svg'

// MATERIAL-UI
import { TextField, InputAdornment, IconButton, Grid } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import {
  dropdownListMarketMenu,
  dropdownListNewsMenu,
  dropdownListInsightsMenu,
  dropdownListMyWorkMenu,
} from 'constants/dropdownRoutes'

import DropdownMenu from 'components/DropdownMenu/DropdownMenu'

const Menu2 = () => {
  const history = useHistory()
  const dropdownRef = useRef(null)
  const [selectedMenuItem, setSelectedMenuItem] = useState(null)

  const handleSelectMenuItem = item => {
    setSelectedMenuItem(prevValue => {
      if (prevValue !== item) {
        return item
      }
      return null
    })
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickOutside = event => {
    if (dropdownRef && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSelectedMenuItem(null)
    }
  }

  return (
    <Grid container className="menu2-container">
      <Grid item xs={1}>
        <div>
          <img src={Logo} alt="" className="logo" onClick={() => history.push('/')} />
        </div>
      </Grid>
      <Grid item xs={5} className="menu-subcontainer" ref={dropdownRef}>
        <Grid
          item
          xs={3}
          className="menu-item-container"
          onClick={() => handleSelectMenuItem('market')}
        >
          <div className="menu-text-style">MARKET</div>
          <DropdownMenu
            elements={dropdownListMarketMenu}
            expanded={selectedMenuItem === 'market'}
          />
        </Grid>
        <Grid
          item
          xs={3}
          className="menu-item-container"
          onClick={() => handleSelectMenuItem('news')}
        >
          <div className="menu-text-style">NEWS</div>
          <DropdownMenu elements={dropdownListNewsMenu} expanded={selectedMenuItem === 'news'} />
        </Grid>
        <Grid
          item
          xs={3}
          className="menu-item-container"
          onClick={() => handleSelectMenuItem('insights')}
        >
          <div className="menu-text-style">INSIGHTS</div>
          <DropdownMenu
            elements={dropdownListInsightsMenu}
            expanded={selectedMenuItem === 'insights'}
          />
        </Grid>
        <Grid
          item
          xs={3}
          className="menu-item-container"
          onClick={() => handleSelectMenuItem('my-work')}
        >
          <div className="menu-text-style">MY WORK</div>
          <DropdownMenu
            elements={dropdownListMyWorkMenu}
            expanded={selectedMenuItem === 'my-work'}
          />
        </Grid>
      </Grid>
      <Grid item xs={6} className="menu-subcontainer-22">
        <TextField
          className={'textArea'}
          fullWidth
          name="text"
          type="text"
          //   value={value || ''}
          //   onChange={e => handleSearch(e)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton aria-label="toggle password visibility">
                  <SearchIcon className={'sendIcon'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
}

Menu2.propTypes = {}

export default Menu2
