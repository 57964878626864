import React, { useRef, useState } from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './SubMenu.scss'

// MATERIAL-UI
import {
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Select,
  MenuItem,
  makeStyles,
  Button,
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search'

// ASSETS
import DownArrow from 'assets/icons/DownArrow.svg'
import UpArrow from 'assets/icons/UpArrow.svg'
import ClearIcon from 'assets/icons/ClearIcon.svg'
import classNames from 'classnames'

// CONSTANTS
import regions from 'constants/usaRegions'
import sectors from 'constants/sectors'
import industries from 'constants/industries'

const CreateScreener = ({
  handleClickSearch = () => {},
  handleClickSaveScreener = () => {},
  selectRef = null,
}) => {
  const classes = useStyles()

  const [screenerName, setScreenerName] = useState('')
  const [priceOption, setPriceOption] = useState('greater')
  const [currency, setCurrency] = useState('usd')
  const [priceValue, setPriceValue] = useState(0)
  const [marketCapOption, setMarketCapOption] = useState('small')
  const [region, setRegion] = useState('Alabama')
  const [sector, setSector] = useState('')
  const [industry, setIndustry] = useState('')

  const handleChangeScreenerName = event => {
    setScreenerName(event.target.value)
  }

  const handleSelectPriceOption = event => {
    setPriceOption(event.target.value)
  }

  const handleSelectCurrency = event => {
    setCurrency(event.target.value)
  }

  const handleChangePriceValue = event => {
    setPriceValue(event.target.value)
  }

  const handleChangeMarketCapOption = (event, newAlignment) => {
    setMarketCapOption(newAlignment)
  }

  const handleChangeRegion = event => {
    setRegion(event.target.value)
  }

  const handleChangeSector = event => {
    setSector(event.target.value)
  }

  const handleChangeIndustry = event => {
    setIndustry(event.target.value)
  }

  return (
    <Grid container className="use-screener-container">
      <Grid item xs={7} className="use-screener-filters-container">
        <div className="use-screener-filter-container">
          <div className="use-screener-filter-row">
            <img src={ClearIcon} alt="" className="clear-icon" />
            <div className="use-screener-filter-label">Screener name</div>
          </div>
          <div className="use-screener-filter-data">
            <TextField
              className={classNames('select', 'text-field', 'big-variant')}
              fullWidth
              name="text"
              placeholder="Type name"
              value={screenerName || ''}
              onChange={handleChangeScreenerName}
            />
          </div>
          <div className="use-screener-filter-row">
            <img src={ClearIcon} alt="" className="clear-icon" />
            <div className="use-screener-filter-label">Price (Intraday)</div>
          </div>
          <div className="use-screener-filter-data">
            <Select
              className="select"
              value={priceOption}
              onChange={handleSelectPriceOption}
              MenuProps={{
                classes: {
                  paper: classes.dropdownStyle,
                  root: classes.root,
                },
                disableScrollLock: true,
              }}
            >
              <MenuItem value={`greater`}>Greater than </MenuItem>
              <MenuItem value={`less`}>Less than</MenuItem>
              <MenuItem value={`equals`}>Equals</MenuItem>
              <MenuItem value={`between`}>Between</MenuItem>
            </Select>
            <TextField
              className={classNames('select', 'text-field')}
              fullWidth
              name="text"
              type="number"
              placeholder="Type value"
              value={priceValue || ''}
              onChange={handleChangePriceValue}
            />
            <Select
              className={classNames('select', 'small-variant')}
              value={currency}
              onChange={handleSelectCurrency}
              MenuProps={{
                classes: { paper: classes.dropdownStyle },
              }}
            >
              <MenuItem value={`usd`}>USD </MenuItem>
              <MenuItem value={`gbp`}>GBP</MenuItem>
              <MenuItem value={`eur`}>EUR</MenuItem>
            </Select>
          </div>
          <div className="use-screener-filter-row">
            <img src={ClearIcon} alt="" className="clear-icon" />
            <div className="use-screener-filter-label">Market Cap (Intraday)</div>
          </div>
          <div className="use-screener-filter-data">
            <ToggleButtonGroup
              value={marketCapOption}
              exclusive
              onChange={handleChangeMarketCapOption}
              aria-label="text alignment"
            >
              <ToggleButton value="small">Small Cap</ToggleButton>
              <ToggleButton value="mid">Mid Cap</ToggleButton>
              <ToggleButton value="large">Large Cap</ToggleButton>
              <ToggleButton value="mega">Mega Cap</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="use-screener-filter-row">
            <img src={ClearIcon} alt="" className="clear-icon" />
            <div className="use-screener-filter-label">Region</div>
          </div>
          <div className="use-screener-filter-data">
            <Select
              className={classNames('select', 'big-variant')}
              value={region}
              onChange={handleChangeRegion}
              MenuProps={{
                classes: { paper: classes.dropdownStyle },
              }}
            >
              {regions.map((item, index) => (
                <MenuItem value={`${item.name}`} key={`regions--${index}`}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="use-screener-filter-row">
            <img src={ClearIcon} alt="" className="clear-icon" />
            <div className="use-screener-filter-label">Sector</div>
          </div>
          <div className="use-screener-filter-data">
            <Select
              className={classNames('select', 'big-variant')}
              value={sector}
              onChange={handleChangeSector}
              MenuProps={{
                classes: { paper: classes.dropdownStyle },
              }}
            >
              {sectors.map((item, index) => (
                <MenuItem value={`${item}`} key={`regions--${index}`}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="use-screener-filter-row">
            <img src={ClearIcon} alt="" className="clear-icon" />
            <div className="use-screener-filter-label">Industry</div>
          </div>
          <div className="use-screener-filter-data">
            <Select
              className={classNames('select', 'big-variant')}
              value={industry}
              onChange={handleChangeIndustry}
              MenuProps={{
                classes: { paper: classes.dropdownStyle },
              }}
            >
              {industries.map((item, index) => (
                <MenuItem value={`${item}`} key={`regions--${index}`}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </Grid>
      <Grid item xs={5} className="use-screener-results-container">
        <div className="use-screener-results-label">Results</div>
        <div className="use-screener-results-value">41012</div>
        <div className="use-screener-buttons-container">
          <Button
            className={classNames('use-screener-button', 'save-button')}
            onClick={handleClickSaveScreener}
          >
            Save Screener
          </Button>
          <Button className="use-screener-button" onClick={handleClickSearch}>
            Search
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

CreateScreener.propTypes = {
  handleClickSaveScreener: PropTypes.func.isRequired,
  handleClickSearch: PropTypes.func.isRequired,
  selectRef: PropTypes.object,
}

const SubMenu = () => {
  const dropdownRef = useRef(null)
  const selectRef = useRef(null)

  const [isScreenerExpanded, setIsScreenerExpanded] = useState(false)

  const handleClickScreener = () => {
    setIsScreenerExpanded(prevValue => !prevValue)
  }

  const handleClickSaveScreener = () => {
    setIsScreenerExpanded(false)
  }

  const handleClickSearch = () => {
    setIsScreenerExpanded(false)
  }

  return (
    <div className="submenu-container">
      <div className="submenu-title">SHARES</div>
      <Grid container direction="column">
        <Grid item xs={12} sm={6} className="search-container">
          <TextField
            className="search-bar"
            fullWidth
            name="text"
            type="text"
            placeholder="Find Symbol"
            //   value={value || ''}
            //   onChange={e => handleSearch(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton aria-label="toggle password visibility">
                    <SearchIcon className="search-icon" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <div ref={dropdownRef}>
          <div className="screener-container" onClick={handleClickScreener}>
            <>
              <div className="screener-text">Use screener</div>
              <div className="screener-icon">
                <img src={isScreenerExpanded ? UpArrow : DownArrow} alt={''} />
              </div>
            </>
          </div>
          {isScreenerExpanded && (
            <CreateScreener
              handleClickSaveScreener={handleClickSaveScreener}
              handleClickSearch={handleClickSearch}
              selectRef={selectRef}
            />
          )}
        </div>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles({
  dropdownStyle: {
    '& .MuiListItem-root': {
      fontFamily: 'Montserrat',
      fontSize: '15px',
      color: '#0c2a4e',
    },
  },
  root: {
    position: 'absolute !important',
  },
})

SubMenu.propTypes = {}

export default SubMenu
