import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line
import moment from 'moment'
import { useHistory } from 'react-router-dom'

// STYLE
import './ScreenersTable.scss'

// MATERIAL-UI
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core'

// ASSETS
import DeleteIcon from 'assets/icons/ClearIcon.svg'

// COMPONENTS
let tableHead = ['Name of screener', 'Creation date', 'Matches', 'Delete']

let mock = [
  {
    name: 'Screener name 1',
    date: new Date(),
    matches: 55,
  },
  {
    name: 'Screener name 1',
    date: new Date(),
    matches: 55,
  },
  {
    name: 'Screener name 1',
    date: new Date(),
    matches: 55,
  },
  {
    name: 'Screener name 1',
    date: new Date(),
    matches: 55,
  },
]

const ScreenersTable = ({ data = [], title = null, type = null }) => {
  const history = useHistory()

  const handleNavigateToEntry = () => {
    history.push('/my-work/screeners/id')
  }

  return (
    <TableContainer component={Paper} className="screeners-table-container">
      <div className="screeners-table-title"> {title}</div>
      <Table>
        <TableHead>
          <TableRow>
            {tableHead.map((item, index) => (
              <TableCell key={`${item}_${index}`}>{item}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {mock.map((item, index) => (
            <TableRow key={`screener_${index}`} onClick={handleNavigateToEntry}>
              <TableCell>{item.name}</TableCell>
              <TableCell>{moment(item.date).format('DD.MM.YYYY')}</TableCell>
              <TableCell>{item.matches}</TableCell>
              <TableCell>
                <img src={DeleteIcon} alt="" className="clear-icon" />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ScreenersTable.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string,
}

export default ScreenersTable
