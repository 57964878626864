export const data = [
  { date: 'Oct 27,2020', open: 476.12, high: 512.12, low: 412.12, close: 501.12, volume: 15.123 },
  { date: 'Oct 27,2020', open: 476.12, high: 512.12, low: 412.12, close: 501.12, volume: 15.123 },
  { date: 'Oct 27,2020', open: 476.12, high: 512.12, low: 412.12, close: 501.12, volume: 15.123 },
  { date: 'Oct 27,2020', open: 476.12, high: 512.12, low: 412.12, close: 501.12, volume: 15.123 },
  { date: 'Oct 27,2020', open: 476.12, high: 512.12, low: 412.12, close: 501.12, volume: 15.123 },
  { date: 'Oct 27,2020', open: 476.12, high: 512.12, low: 412.12, close: 501.12, volume: 15.123 },
  { date: 'Oct 27,2020', open: 476.12, high: 512.12, low: 412.12, close: 501.12, volume: 15.123 },
  { date: 'Oct 27,2020', open: 476.12, high: 512.12, low: 412.12, close: 501.12, volume: 15.123 },
  { date: 'Oct 27,2020', open: 476.12, high: 512.12, low: 412.12, close: 501.12, volume: 15.123 },
]
