import React, { useState } from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './MenuTabs.scss'

// MATERIAL-UI
import { Grid } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const MenuTabs = ({ tabs = [] }) => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Grid container className="menu-tabs-container">
      <Tabs value={value} onChange={handleChange}>
        {tabs && tabs.map((item, index) => <Tab label={item.label} key={`menu-tab-${index}`} />)}
      </Tabs>
      {tabs && tabs.map((item, index) => value === item.value && item.component)}
    </Grid>
  )
}

MenuTabs.propTypes = {
  tabs: PropTypes.array,
}

export default MenuTabs
