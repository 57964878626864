import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './ChartsAndHistoricalData.scss'

// MATERIAL-UI
import { Grid } from '@material-ui/core'

//COMPONENTS
import ChartsAndHistoricalDataTable from './components/ChartsAndHistoricalDataTable'
import BigChartComponent from 'components/BigChartComponent/BigChartComponent'

const ChartsAndHistoricalData = () => {
  return (
    <Grid container className="menu-tab-container" direction="column" wrap="nowrap">
      <BigChartComponent />
      <ChartsAndHistoricalDataTable />
    </Grid>
  )
}

ChartsAndHistoricalData.propTypes = {}

export default ChartsAndHistoricalData
