import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

// STYLE
import './DropdownMenu.scss'

const DropdownMenu = ({ expanded = false, elements = [] }) => {
  return (
    <>
      {expanded && (
        <div className="dropdown-menu">
          {elements.map((item, index) => (
            <div key={`dropdown-menu-${index}`}>
              {item.identifier && (
                <NavLink
                  key={`dropdown-menu-link-${index}`}
                  to={item.path}
                  activeClassName="active-link"
                  className="dropdown-menu-title"
                >
                  {item.identifier}
                </NavLink>
              )}
              <div className="dropdown-menu-routes">
                {item.routes.map((link, i) => (
                  <NavLink
                    key={`dropdown-menu-link-${i}`}
                    to={link.path}
                    activeClassName="active-link"
                    className={classNames(
                      'dropdown-menu-link',
                      item.identifier && 'dropdown-menu-spacing',
                    )}
                  >
                    {link.title}
                  </NavLink>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

DropdownMenu.propTypes = {
  expanded: PropTypes.bool,
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      identifier: PropTypes.string,
      routes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          path: PropTypes.string,
        }),
      ),
    }),
  ),
}

export default DropdownMenu
