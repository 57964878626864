import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './PropertyProfile.scss'

// MATERIAL-UI
import { Grid } from '@material-ui/core'

// ASSETS
import Photo from 'assets/images/PropertyProfilePhoto.svg'
import MapLocation from 'assets/images/MapLocation.svg'

// COMPONENTS
import HoldersTable from './HoldersTable'

// MOCKS
import {
  investmentHighlights,
  aboutCompany,
  retail,
  hotels,
  transportation,
  keyExecutives,
  accessAndJaborForce,
  institutionalHolders,
} from 'mocks/CompanyProfileMocks'
import classNames from 'classnames'

const PropertyProfile = () => {
  return (
    <Grid container className="property-profile-container">
      <Grid container>
        <Grid item xs={4} className="property-profile-description">
          <div className="company-name">Crosswind Dr (CRS)</div>
          <div className="company-small-details">Columbus, OH 95110-2704</div>
          <div className="company-small-details">United States</div>
          <div className="company-small-details">http://www.Crosswind.com</div>
          <div className="company-small-details">Sector(s): Technology</div>
          <div className="company-small-details">Industry: Manufacturing</div>
          <div className="company-small-details">Full Time Employees: 22,634</div>
        </Grid>
        <Grid item xs={8} className="property-profile-photo">
          <img src={Photo} alt={''} className="property-photo" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className="property-profile-section-title">
          Investment Highlights
        </Grid>
        <Grid item xs={12} className="property-profile-description">
          {investmentHighlights.map((item, index) => {
            return (
              <div className="row" key={`${item}_${index}`}>
                {item}
              </div>
            )
          })}
        </Grid>
        <Grid item xs={12} className="property-profile-section-title">
          About CROSSWIND DR
        </Grid>
        <Grid item xs={12} className="property-profile-description">
          {aboutCompany}
        </Grid>
        <Grid item xs={12} className="property-profile-section-title">
          About SW COLUMBUS
        </Grid>
        <Grid item xs={12} className="property-profile-description">
          {aboutCompany}
          {aboutCompany}
          {aboutCompany}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={7}>
          <div className="property-profile-section-title">Nearby Amenties</div>
          <div className="property-profile-section-subtitle">Retail</div>
          {retail.map((item, index) => {
            return (
              <Grid container className="entry" key={`${item}_${index}`}>
                <Grid item xs={4}>
                  {item.name}
                </Grid>
                <Grid item xs={4}>
                  {item.type}
                </Grid>
                <Grid item xs={4}>
                  {item.duration}
                </Grid>
              </Grid>
            )
          })}
          <div className="property-profile-section-subtitle">Hotels</div>
          {hotels.map((item, index) => {
            return (
              <Grid container className="entry" key={`${item}_${index}`}>
                <Grid item xs={4}>
                  {item.name}
                </Grid>
                <Grid item xs={4}>
                  {item.noOfStars}
                </Grid>
                <Grid item xs={4}>
                  {item.duration}
                </Grid>
              </Grid>
            )
          })}
        </Grid>
        <Grid item xs={5}>
          <div className="property-profile-section-title">Map Location</div>
          <img src={MapLocation} alt={''} className="map-photo" />
        </Grid>
        <Grid container>
          <div className="property-profile-section-subtitle">Transportation</div>
          {transportation.map((item, index) => {
            return (
              <Grid container className="entry" key={`${item}_${index}`}>
                <Grid item xs={6}>
                  {item.name}
                </Grid>
                <Grid item xs={3}>
                  {item.type}
                </Grid>
                <Grid item xs={2}>
                  {item.distance}
                </Grid>
                <Grid item xs={1}>
                  {item.duration}
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={7}>
          <div className="property-profile-section-title">Key Executives</div>
          {keyExecutives.map((item, index) => {
            return (
              <Grid container className="entry" key={`${item}_${index}`}>
                <Grid item xs={5}>
                  {item.name}
                </Grid>
                <Grid item xs={7}>
                  {item.title}
                </Grid>
              </Grid>
            )
          })}
        </Grid>
        <Grid item xs={5}>
          <div className="property-profile-section-title">Access And Labor Force</div>
          {accessAndJaborForce.map((item, index) => {
            return (
              <Grid container className="entry" key={`${item}_${index}`}>
                <Grid item xs={10}>
                  {item.indicator}
                </Grid>
                <Grid item xs={2} className="padding-right-50">
                  {item.value}
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      <Grid container direction="column">
        <div className={classNames('property-profile-section-title', 'margin-bottom-30')}>
          Key Holders
        </div>
        <div className={classNames('property-profile-section-subtitle', 'margin-0')}>
          Top Institutional Holders
        </div>
        <HoldersTable data={institutionalHolders} />
        <div className={classNames('property-profile-section-subtitle', 'margin-top-40')}>
          Top Mutual Fund Holders
        </div>
        <HoldersTable data={institutionalHolders} />
      </Grid>
    </Grid>
  )
}

PropertyProfile.propTypes = {}

export default PropertyProfile
