export const symbols = [
  { name: 'Crs', price: 452.12, priceChange: 5.12, pricePercentageChange: 9.12 },
  { name: 'Fmt', price: 452.12, priceChange: -5.12, pricePercentageChange: -9.12 },
  { name: 'Fmm', price: 452.12, priceChange: 5.12, pricePercentageChange: 9.12 },
  { name: 'Ptm', price: 452.12, priceChange: -5.12, pricePercentageChange: -9.12 },
  { name: 'Tmss', price: 452.12, priceChange: 5.12, pricePercentageChange: 9.12 },
  { name: 'Crs', price: 452.12, priceChange: 5.12, pricePercentageChange: 9.12 },
  { name: 'Fmt', price: 452.12, priceChange: -5.12, pricePercentageChange: -9.12 },
  { name: 'Fmm', price: 452.12, priceChange: 5.12, pricePercentageChange: 9.12 },
  { name: 'Ptm', price: 452.12, priceChange: -5.12, pricePercentageChange: -9.12 },
]
