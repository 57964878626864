import * as React from 'react'
import PropTypes from 'prop-types'

function TwitterLogo({ fill = '', ...others }) {
  return (
    <svg
      data-name="Icon: Facebook"
      xmlns="http://www.w3.org/2000/svg"
      width={16.533}
      height={24}
      viewBox="0 0 25 22.278"
      {...others}
    >
      <path data-name="Rectangle 1457" fill="none" d="M0 0H16V16H0z" />
      <path
        data-name="Path 2"
        d="M45.917 22.278A14.454 14.454 0 0060.5 7.694V7A11.29 11.29 0 0063 4.361a11.529 11.529 0 01-2.917.833 5.4 5.4 0 002.222-2.778 12.739 12.739 0 01-3.194 1.25A4.96 4.96 0 0055.361 2a5.218 5.218 0 00-5.139 5.139 2.708 2.708 0 00.139 1.111 14.36 14.36 0 01-10.555-5.417 5.319 5.319 0 00-.694 2.639 5.518 5.518 0 002.222 4.306 4.682 4.682 0 01-2.361-.694 5.076 5.076 0 004.167 5 4.282 4.282 0 01-1.389.139 2.364 2.364 0 01-.972-.139 5.262 5.262 0 004.861 3.611 10.486 10.486 0 01-6.389 2.222A3.845 3.845 0 0138 19.778a13.11 13.11 0 007.917 2.5"
        transform="translate(-38)"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  )
}

TwitterLogo.propTypes = {
  fill: PropTypes.string,
}

export default TwitterLogo
