import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './Statistics.scss'

// MATERIAL-UI
import { Grid } from '@material-ui/core'

//COMPONENTS
import ValuationMeasuresTable from './ValuationMeasuresTable'
import IndicatorValueTable from './IndicatorValueTable'

// MOCKS
import {
  fiscalYear,
  profitability,
  incomeStatement,
  incomeStatement2,
  cashFlowStatement,
  stockPriceHistory,
  shareStatistics,
  dividendsAndSplits,
} from 'mocks/StatisticsMocks'

const Statistics = () => {
  return (
    <Grid container className="menu-tab-container" direction="column" wrap="nowrap">
      <ValuationMeasuresTable />

      <Grid container className="menu-tab-container">
        <Grid item xs={6} className="left-row">
          <Grid item xs={12}>
            <div className="section-title">Financial Highlights</div>
          </Grid>
          <IndicatorValueTable title="Fiscal year" data={fiscalYear} />
          <IndicatorValueTable title="Profitability" data={profitability} />
          <IndicatorValueTable title="Income Statement" data={incomeStatement} />
          <IndicatorValueTable title="Income Statement" data={incomeStatement2} />
          <IndicatorValueTable title="Cash Flow Statemetn" data={cashFlowStatement} />
        </Grid>

        <Grid item xs={6} className="right-row">
          <Grid item xs={12}>
            <div className="section-title">Trading Information</div>
          </Grid>
          <IndicatorValueTable title="Stock Price History" data={stockPriceHistory} />
          <IndicatorValueTable title="REbS Statistics" data={shareStatistics} />
          <IndicatorValueTable title={`Distributed Proceeds & Splits`} data={dividendsAndSplits} />
        </Grid>
      </Grid>
    </Grid>
  )
}

Statistics.propTypes = {}

export default Statistics
