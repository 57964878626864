import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// MATERIAL-UI
import { Grid } from '@material-ui/core'

// STYLE
import './NewsSection.scss'

// COMPONENTS
import NewsCard from 'components/NewsCard/NewsCard'
import ProfessionalsOpinionCard from './components/ProfessionalsOpinionCard'

const NewsSection = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={6} className="professionals-opinion">
        <Grid item xs={12} sm={12} className="professionals-opinion-title">
          Professionals Opinion
        </Grid>
        <ProfessionalsOpinionCard />
        <ProfessionalsOpinionCard />
      </Grid>
      <Grid item xs={12} sm={6} className="lastest-news">
        <Grid item xs={12} sm={12} className="lastest-news-title">
          Lastest News
        </Grid>
        <NewsCard />
        <NewsCard />
        <NewsCard />
      </Grid>
    </Grid>
  )
}

NewsSection.propTypes = {}

export default NewsSection
