import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './NewsCard.scss'

// MATERIAL-UI
import { Grid } from '@material-ui/core'

// ASSETS
import PreviewImage from 'assets/images/LastestNewsImage.svg'

const description =
  'ERLIN, Nov 2 (Reuters) - German factories saw record growth in new orders in October, a survey showed on Monday, fuelling a recovery which is under threat from new restrictions imposed by Chancellor Angela Merkel to tackle rising coronavirus infections...'

const NewsCard = () => {
  return (
    <Grid className="news-card-container">
      <Grid className="news-card-image-container">
        <img src={PreviewImage} alt={''} className="news-card-image" />
      </Grid>
      <Grid className="news-card-content">
        <Grid className="news-card-sector">Industrial Sector</Grid>
        <Grid className="news-card-title">
          German factories had surge in new business in October ahead of restrictions -PMI
        </Grid>
        <Grid className="news-card-description">
          {description.length > 150 ? `${description.substring(0, 150)}...` : description}
        </Grid>
        <Grid className="news-card-bottom">
          <div className="date">OCT 29 2020</div>
          <div className="view-more">View More ></div>
        </Grid>
      </Grid>
    </Grid>
  )
}

NewsCard.propTypes = {}

export default NewsCard
