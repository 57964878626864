import React from 'react'
// eslint-disable-next-line
import PropTypes from 'prop-types'

// STYLE
import './CompanyPreview.scss'

// MATERIAL-UI
import { Grid } from '@material-ui/core'

// ASSETS
import CompanyImage from 'assets/images/CompanyImage.svg'

const CompanyPreview = () => {
  return (
    <Grid container className="company-preview-container">
      <Grid item xs={1} sm={1} className="green-bar" />
      <Grid item xs={11} sm={6} className="company-preview-details">
        <Grid item xs={12} sm={12} className="company-name">
          Crosswind Dr (CRS)
        </Grid>
        <Grid item xs={12} sm={12} className="company-price-per-unit">
          495.96
        </Grid>
        <Grid item xs={12} sm={12} className="company-close-transaction">
          At close: October 21 4:00 EDT
        </Grid>
        <Grid item xs={12} sm={12} className="add-button-container">
          <div className="add-button">
            <span className="add">+</span> ADD TO WATCHLIST
          </div>
        </Grid>
        <Grid item xs={12} sm={12} className="add-button-container">
          <div className="add-button">
            <span className="add">+</span> ADD TO PORTFOLIO
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5} className="company-preview-image-container">
        <img src={CompanyImage} alt={''} className="company-preview-image" />
      </Grid>
    </Grid>
  )
}

CompanyPreview.propTypes = {}

export default CompanyPreview
